import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,    
    NavbarBrand,
    NavItem,
    Navbar,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Button

} from "reactstrap";

import { useTranslation } from 'react-i18next';
import LogoGrande from "../assets/img/logo.png";
import LogoMille from "../assets/img/brand/white.png";
import OthelloWhatsApp from "./OthelloWhatsApp";

function OthelloNavbar(props)
{
    const { t } = useTranslation();

    const [collapseOpen, toggleCollapse] = React.useState(false);
    const [DesdeSoporte] = React.useState(props.DesdeSoporte);

    React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"));
    // initialise
    headroom.init();
  });

    let navbarType = "";

    switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      break;
    case "white":
      navbarType = "bg-white";
      break;
    default:
      navbarType = "bg-default navbar-dark";
      break;
  }

    const [menu, setMenu] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() => {
        fetch('api/Data/GetAreasMenu')
            .then(response => response.json())
            .then(data => {
                setMenu(data);
                setLoading(false)              
            }).catch(error => {
                setMenu([]);
                setLoading(false)
            });

    }, [loading]);



    const datamenu = !loading && menu != null && menu != null && menu.length > 0 ?
        menu.map(x =>
        {
            //Si tiene subareas
            if (x.itemssubmenu != null && x.itemssubmenu.length > 0)
            {
                let opcionesmenu = x.itemssubmenu.map(y =>
                {
                    return <DropdownItem to={y.linksubarea} tag={Link}>{/*<i className="ni ni-align-left-2 text-primary"></i>*/}{t(y.nombre)}</DropdownItem>
                });

                return <UncontrolledDropdown nav >
                            <DropdownToggle className="mr-2" color="default" caret nav>{t(x.nombre)}</DropdownToggle>
                            <DropdownMenu className="dropdown-danger" right>
                                {opcionesmenu}
                            </DropdownMenu>
                        </UncontrolledDropdown>;
            }
            else
            {            
                //x.tipo != null && x.linkexterno != "" ? "_blank" : "_self"
                return <NavItem >
                            <NavLink href={x.linkarea} target={"_self"}>
                                {t(x.nombre)} <span className="sr-only">(current)</span>
                            </NavLink>
                        </NavItem>;
            }
        }) : "";

    const keyname = !loading && menu != null && menu.length > 0 ? menu[0].userkey : "";

  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"
      >
        <Container>
                  <NavbarBrand className="mr-lg-5" to="/index" tag={Link}>
                      <img alt="..." src={LogoMille}></img>
          </NavbarBrand>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleCollapse(!collapseOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/index">
                    <img alt="millenium-soft"
                                          src={LogoGrande}
                    ></img>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto"
              navbar
            >
                          {datamenu}
                          <NavItem style={{ width: 138, paddingBottom: 10 }}>
                              <Button color="info" type="button" href='/soluciones/kit-digital'>
                                  <i class="fas fa-mobile"></i> Kit Digital
                              </Button>
                          </NavItem>
                          <NavItem style={{ width: 135, paddingBottom: 10 }}>
                              <Button outline color="info" type="button"  href="tel:+34915020400">
                                   91 502 04 00
                              </Button>                              
                          </NavItem>
                          <NavItem style={{ width: keyname !== "" ? 75 : "", paddingBottom: 10  }}>
                              <Button color="primary" type="button" href={keyname !== "" ? '/micuenta' : '/login'}>
                                  <i class="fas fa-user-alt"></i>{" "}{keyname}
                              </Button>
                          </NavItem>
                          {(DesdeSoporte == null || DesdeSoporte !== "true") ?
                              <NavItem style={{ paddingBottom: 10 }}>
                                  <Button color="danger" type="button" href='/soporte'>
                                      <i class="fas fa-headset"></i>
                              </Button>
                              </NavItem> : <NavItem style={{ width: 150, paddingBottom: 15 }} />}
                          {(DesdeSoporte == null || DesdeSoporte !== "true") ?
                          <NavItem style={{ width: 135, paddingBottom: 10 }}>
                              <OthelloWhatsApp soloicono={true} />
                              </NavItem> : <NavItem style={{ width: 150, paddingBottom: 15 }} />}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

OthelloNavbar.defaultProps = {
  type: "dark",
};

OthelloNavbar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default OthelloNavbar;
