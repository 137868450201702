import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
    Button, 
    Modal,
    Container,   
    Row,
    Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

function OthelloModalBase({...props })
{
    //variables de las propiedas recibidas
    const { open, tituloliteral, titulo, textobotonaceptar } = props;

    const { t } = useTranslation();


    const handleClose = (modal, estado) =>
    {
        //if (props.autoclosebtaceptar == null || props.autoclosebtaceptar) {
        //    //var x = [];
        //    //x[modal] = false;
        //    //this.setState(x);
        //    setmodal(false);
        //}

        if (props.onCloseResulModal != null)
            props.onCloseResulModal(props.modo, estado);      
    }

    //toggle={() => handleClose("modal", false)}

    return (
        <>
            <Modal isOpen={open}  className="modal-dialog-centered modal-lg">
                <div className="modal-header">
                    <span style={{ fontSize: 15, fontWeight: "bold" }}>
                        <b>{t != null && tituloliteral != null ? t(tituloliteral) : titulo}</b>
                    </span>
                </div>
               <br/>
                <Container>
                    <div>
                        {props.children}
                    </div>
                    <Row>
                        <Col md="12">
                            <div className="pull-right">
                                <Button className="btn-wd" color="danger" outline style={{ marginRight: 5 }} onClick={() => handleClose("modal", true)}>{textobotonaceptar != null ? textobotonaceptar : t("_aceptar")}</Button>
                                <Button className="btn-wd" color="danger" outline style={{ marginRight: 5 }} onClick={() => handleClose("modal", false)}>{t("_cancelar")}</Button>
                            </div>
                        </Col>
                    </Row>                   
                    <div outline style={{ marginRight: 5, height:25 }} />
                </Container>               
                
            </Modal>
        </>
    );
}

export default OthelloModalBase;

OthelloModalBase.propTypes = {
    tituloliteral: PropTypes.string,
    titulo: PropTypes.string,
    open: PropTypes.bool, //Se gestioana con DataStateModal
    //modo: PropTypes.object, //Constante ModoModal
    //botones: PropTypes.object, //Los botones se gestionan con MessageOthelloBoxButtons
    //textobotones: PropTypes.object, //Array de literales 
    //autoclosebtaceptar: PropTypes.bool, //indica si se quiere que se cierra el modal automaticamente al dar al pulsar aceptar (true si no se define)
    onCloseResulModal: PropTypes.object,
    //retornoCResultadoOthello: PropTypes.object,
    //onCloseCResulRetorno: PropTypes.object,
    //maxWidth: PropTypes.object //Las opciones xs'| 'sm' | 'md' | 'lg' | 'xl' | false| string
};