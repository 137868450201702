import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import { useTranslation } from 'react-i18next';

//Channel
import siteminder from "../assets/img/integracion/siteminder.png";
import parityrate from "../assets/img/integracion/parityrate.png";
import availpro from "../assets/img/integracion/availpro.png";
import ratetiger from "../assets/img/integracion/ratetiger.png";
import synergy from "../assets/img/integracion/synergy.png";
import yieldplanet from "../assets/img/integracion/yieldplanet.png";

//Marketing
import controldata from "../assets/img/integracion/controldata.png";
import smartrevenue from "../assets/img/integracion/smartrevenue.png";
import reviewpro from "../assets/img/integracion/reviewpro.jpg";
import fideltour from "../assets/img/integracion/fideltour.png";
import trustyou from "../assets/img/integracion/trustyou.png";
import customeralliance from "../assets/img/integracion/customeralliance.png";
import beonprice from "../assets/img/integracion/beonprice.png";
import journeytok from "../assets/img/integracion/journeytok.png";
import bedsrevenue from "../assets/img/integracion/bedsrevenue.png";
import hotellink from "../assets/img/integracion/hotellink.png";
import lybra from "../assets/img/integracion/lybra.png";
import jaippy from "../assets/img/integracion/jaippy.png";
import querytech from "../assets/img/integracion/querytech.png";
import connect from "../assets/img/integracion/connectpushtech.png";

//CashBox
import cashguard from "../assets/img/integracion/cashguard.png";
import cashlogic from "../assets/img/integracion/cashlogic.png";
import cashkeeper from "../assets/img/integracion/cashkeeper.png";

//Dom�tica
import char from "../assets/img/integracion/char.png";
import tesa from "../assets/img/integracion/tesa.png";
import inhova from "../assets/img/integracion/inhova.png";
import yuvod from "../assets/img/integracion/yuvod.png";
import parkingpro from "../assets/img/integracion/ParkingPro.png";
import tigertms from "../assets/img/integracion/tigertms.png";
import magallanesesferize from "../assets/img/integracion/magallanesesferize.png";

//Otros
import roommatik from "../assets/img/integracion/roommatik.png";
import rossaconnect from "../assets/img/integracion/rossaconnect.png";
import paytef from "../assets/img/integracion/paytef.png";
import gesvi from "../assets/img/integracion/gesvi.jpg";
import delta from "../assets/img/integracion/delta.png";

//Contabilidad
import sage from "../assets/img/integracion/sage.png";
import contaplus from "../assets/img/integracion/contaplus.png";
import a3 from "../assets/img/integracion/a3.jpeg";
import altai from "../assets/img/integracion/altai.png";
import geyce from "../assets/img/integracion/geyce.jpg";
import monitor from "../assets/img/integracion/monitor.png";
import navision from "../assets/img/integracion/navision.png";
import visualconta from "../assets/img/integracion/visualconta.png";
import dimoni from "../assets/img/integracion/dimoni.png";
import datisa from "../assets/img/integracion/datisa.jpg";
import director from "../assets/img/integracion/director.png";
import classicconta from "../assets/img/integracion/classiconta.png";
import voxel_amadeus from "../assets/img/integracion/voxel_ama.png";

import apps from "../assets/img/appsmoviles.jpg";

import { useLocation } from "react-router-dom";

import {
    Nav,
    NavItem,
    NavLink,
    Container,
    TabContent,
    TabPane,
    Row,
    Col,
    Card,
} from "reactstrap";


function SolucionIntegracionesTerceros() {

    const { search } = useLocation();
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(true);
    const [hTabs2, setHTabs2] = React.useState("hTabs2-1");

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() =>
    {
        let url = search;
        let params = new URLSearchParams(url);

        let tabselected = "hTabs2-1";

        if (params.has('info'))
        {
            let selected = params.get('info');

            if (selected != "")
            {
                if (selected == "channel")
                    tabselected = "hTabs2-1";
                else if (selected == "marketing")
                    tabselected = "hTabs2-2";
                else if (selected == "contabilidad")
                    tabselected = "hTabs2-3";
                else if (selected == "domotica")
                    tabselected = "hTabs2-4";

                setHTabs2(tabselected);
            }
        }

        setLoading(false);
    }, [loading]);

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

    function getDataChannels()
    {
        let channels = [];
        channels.push({ imagen: parityrate, url: "https://www.parityrate.com/es/" });
        channels.push({ imagen: siteminder, url: "https://www.siteminder.com/es/" });
        channels.push({ imagen: availpro, url: "https://www.d-edge.com/es/" });
        channels.push({ imagen: ratetiger, url: "https://ratetiger.com/" });
        channels.push({ imagen: synergy, url: "" });
        channels.push({ imagen: yieldplanet, url: "https://www.yieldplanet.com/es/" });
        channels.push({ imagen: parkingpro, url: "https://parkingpro.es/" });

        return channels;
    }

    function getDataMarketing() {
        let marketing = [];
        marketing.push({ imagen: controldata, url: "https://www.revenuecontroldata.com/" });
        marketing.push({ imagen: smartrevenue, url: "https://www.smartrevenue.travel/" });
        marketing.push({ imagen: reviewpro, url: "https://www.reviewpro.com/info/" });
        marketing.push({ imagen: fideltour, url: "https://www.fideltour.com/es-es/crm-hoteles" });
        marketing.push({ imagen: trustyou, url: "https://www.trustyou.com/es/" });
        marketing.push({ imagen: customeralliance, url: "https://www.customer-alliance.com/es/" });
        marketing.push({ imagen: beonprice, url: "https://beonprice.com/product/" });
        marketing.push({ imagen: journeytok, url: "https://journeytok.com/" });
        marketing.push({ imagen: bedsrevenue, url: "https://www.bedsrevenue.com/" });
        marketing.push({ imagen: hotellink, url: "https://www.hotellinksolutions.com/es/" });
        marketing.push({ imagen: lybra, url: "https://lybra.tech/es/" });
        marketing.push({ imagen: jaippy, url: "https://jaippy.com/" });
        marketing.push({ imagen: querytech, url: "https://hotels-quality.com/website/" });
        marketing.push({ imagen: connect, url: "https://www.pushtech.com/?locale=es" });

        return marketing;
    }

    function getDataContabilidad() {
        let conta = [];
        conta.push({ imagen: sage, url: "https://www.sage.com/es-es" });
        conta.push({ imagen: contaplus, url: "https://www.sage.com/es-es/productos/sage-contaplus/?utm_gachannelgroup=PaidSearch&utm_source=GOOGLE&utm_medium=PaidSearch&utm_campaign=ES%7CGoogle%7CSmall%7CBrand_Plus/Main(MaxConv-Multiple)NA_SMA50UD_&utm_delivery=Paid&gad=1&gclid=CjwKCAjwl6OiBhA2EiwAuUwWZcFx2pqAXyV9mBOb_yQUmrsNG-EqBCv8fYs_ysuteDWicIUub2yclBoC9IQQAvD_BwE&gclsrc=aw.ds" });
        conta.push({ imagen: a3, url: "https://www.wolterskluwer.com/es-es/solutions/a3erp?utm_source=google&utm_medium=cpc&utm_campaign=C70-a3ERP&utm_content=ERP.br&utm_term=a3%20erp&hsa_acc=2856022180&hsa_cam=965121262&hsa_grp=139956147503&hsa_ad=621995306366&hsa_src=g&hsa_tgt=kwd-354288274040&hsa_kw=a3%20erp&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gad=1&gclid=CjwKCAjwl6OiBhA2EiwAuUwWZWLqrNyiX1d5AmcH3ROLyiBwSqTZCFn_kSC3Hn-LKyai_d8irFWQvxoCg-YQAvD_BwE" });
        conta.push({ imagen: altai, url: "https://www.altai.es/" });
        conta.push({ imagen: geyce, url: "https://www.geyce.es/" });
        conta.push({ imagen: monitor, url: "https://www.monitorerp.com/" });
        conta.push({ imagen: navision, url: "https://www.navision.es/" });
        conta.push({ imagen: visualconta, url: "https://www.goldensoft.com/" });
        conta.push({ imagen: dimoni, url: "https://www.exact.com/es/software/exact-dimoni" });
        conta.push({ imagen: datisa, url: "https://datisa.es" });
        conta.push({ imagen: director, url: "https://www.ncs.es/" });
        conta.push({ imagen: classicconta, url: "https://www.aigclassic.com/" });
        conta.push({ imagen: voxel_amadeus, url: "https://www.voxelgroup.net/es/" });
        

        return conta;        
    }

    function getDataDomotica() {
        let domotica = [];
        domotica.push({ imagen: char, url: "https://www.char.es/" });
        domotica.push({ imagen: tesa, url: "https://www.tesa.es/es" });
        domotica.push({ imagen: inhova, url: "https://www.innovahotel.com/" });
        domotica.push({ imagen: yuvod, url: "https://yuvod.com/es/" });
        domotica.push({ imagen: tigertms, url: "https://www.tigertms.com/" });
        domotica.push({ imagen: magallanesesferize, url: "https://www.esferize.com/" });
        

        return domotica;      
    }

    function getDataCashBox() {
        let cajon = [];
        cajon.push({ imagen: cashguard, url: "" });
        cajon.push({ imagen: cashlogic, url: "https://cashlogy.com/es/" });
        cajon.push({ imagen: cashkeeper, url: "https://www.cashkeeper.es/" });

        return cajon;
    }

    function getDataOtros() {
        let otros = [];
        otros.push({ imagen: roommatik, url: "https://www.roommatik.com/" });
        otros.push({ imagen: rossaconnect, url: "https://rossa.app/" });
        otros.push({ imagen: paytef, url: "https://paytef.es/" });
        otros.push({ imagen: gesvi, url: "http://portal.gesvi.net/" });
        otros.push({ imagen: delta, url: "https://deltainformatica.es/soluciones/delta-id-escaner-de-dni-y-pasaporte/" });

        return otros;
    }

    function renderSlideOpcion(titulo, tabId, imagenes)
    {
        let imagenesfinal = [];

        if (imagenes != null && imagenes.length > 0)
        {
            let imagenesrow = [];
            let index = 0;
            let indexTotal = 0;
            let total = imagenes.length;

            imagenes.forEach(x =>
            {             
                imagenesrow.push(
                    <Col md="4">
                        <div style={{
                            textAlign: 'center',
                            padding: '5px 5px',
                            marginBottom:20,
                            height: 120,
                            /* Center vertically and horizontally */
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <a
                                target={x.url != "" ? "_blank" : ""}
                                href={x.url}
                                rel="noreferrer"
                            >
                            <img
                                style={{ padding: 5, objectFit: "fill", maxHeight: 100 }}
                                alt="..."
                                width="100%"                               
                                src={x.imagen}
                                ></img>
                            </a>
                        </div>
                    </Col>);

                index = index + 1;
                indexTotal = indexTotal + 1;

                if (index == 3 || total == indexTotal)
                {                                                            
                        imagenesfinal.push(
                        <Row>
                            {imagenesrow}
                        </Row>
                   );

                    //limpio los valores
                    index = 0;
                    imagenesrow = [];
                }
            });
        }


        return <TabPane tabId={tabId}>
            <p >
                {t(titulo)}
            </p>            
            {imagenesfinal}            
        </TabPane>
    }
      
  return (
    <>
          <OthelloNavbar type="transparent" />          
          <div className="wrapper">
              <OthelloHeader titulo="_integracionterceros" subtitulo="_integracionesSubTitle" imagen={apps} conbotones={false} />
            {/*  <ProjectsSectionWeb />*/}
              {/*  <OthelloSolucionFeature7 titulo1="_appstitle1WEB" texto1="_appstitle1WEBtxt" titulo2="_appstitle2" texto2="_appstexto2" />*/}
              <div className="wrapper">                                  
                  <div className="project-2 my-5">
                      <Container>
                          <Row>
                          <Col md="10">
                              <div className="nav-wrapper">
                                  <Nav
                                      className="nav-pills-danger nav-fill flex-column flex-md-row"
                                      pills
                                      role="tablist"
                                  >
                                      <NavItem>
                                          <NavLink
                                              className={
                                                  "mb-sm-3 mt-md-3 mt-lg-0 " +
                                                  (hTabs2 === "hTabs2-1" ? "active" : "")
                                              }
                                              href="#pablo"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  setHTabs2("hTabs2-1");
                                              }}
                                          >
                                              <i className="ni ni-cloud-upload-96 mr-2"></i>
                                              Channel
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              className={
                                                  "mb-sm-3 mt-md-3 mt-lg-0 " +
                                                  (hTabs2 === "hTabs2-2" ? "active" : "")
                                              }
                                              href="#pablo"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  setHTabs2("hTabs2-2");
                                              }}
                                              >
                                                  <i class="fas fa-bullhorn mr-2"></i>
                                              Marketing
                                          </NavLink>
                                      </NavItem>
                                      <NavItem>
                                          <NavLink
                                              className={
                                                  "mb-sm-3 mt-md-3 mt-lg-0 " +
                                                  (hTabs2 === "hTabs2-3" ? "active" : "")
                                              }
                                              href="#pablo"
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  setHTabs2("hTabs2-3");
                                              }}
                                              >
                                                  <i class="fas fa-file-invoice mr-2"></i>                                              
                                                Contabilidad
                                          </NavLink>
                                          </NavItem>
                                      <NavItem>
                                        <NavLink
                                            className={
                                                "mb-sm-3 mt-md-3 mt-lg-0 " +
                                                (hTabs2 === "hTabs2-4" ? "active" : "")
                                            }
                                            href="#pablo"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setHTabs2("hTabs2-4");
                                            }}
                                        >
                                            <i class="fas fa-laptop-house mr-2"></i>                                                  
                                            Domotica
                                        </NavLink>
                                    </NavItem>
                                      <NavItem>
                                        <NavLink
                                            className={
                                                "mb-sm-3 mt-md-3 mt-lg-0 " +
                                                (hTabs2 === "hTabs2-5" ? "active" : "")
                                            }
                                            href="#pablo"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setHTabs2("hTabs2-5");
                                            }}
                                        >
                                            <i class="fas fa-cash-register mr-2"></i>                                                  
                                            Cashbox
                                        </NavLink>
                                    </NavItem>
                                      <NavItem>
                                        <NavLink
                                            className={
                                                "mb-sm-3 mt-md-3 mt-lg-0 " +
                                                (hTabs2 === "hTabs2-6" ? "active" : "")
                                            }
                                            href="#pablo"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setHTabs2("hTabs2-6");
                                            }}
                                        >
                                            <i class="fas fa-info-circle  mr-2"></i>                                                  
                                            Otros
                                        </NavLink>
                                    </NavItem>
                                  </Nav>
                              </div>
                                  <Card className="card-plain">                                    
                                      <TabContent className="tab-space" activeTab={hTabs2}>
                                          {renderSlideOpcion("_integrachannel", "hTabs2-1", getDataChannels())}
                                          <TabPane tabId="hTabs2-2">
                                              {renderSlideOpcion("_integramarketing", "hTabs2-2", getDataMarketing())}
                                      </TabPane>
                                          <TabPane tabId="hTabs2-3">
                                              {renderSlideOpcion("_integracontabilidad", "hTabs2-3", getDataContabilidad())}
                                          </TabPane>
                                          <TabPane tabId="hTabs2-4">
                                              {renderSlideOpcion("_integradomotica", "hTabs2-4", getDataDomotica())}
                                          </TabPane>
                                          <TabPane tabId="hTabs2-5">
                                              {renderSlideOpcion("_integracashbox", "hTabs2-5", getDataCashBox())}
                                          </TabPane>
                                          <TabPane tabId="hTabs2-6">
                                              {renderSlideOpcion("_integraotros", "hTabs2-6", getDataOtros())}
                                          </TabPane>
                                  </TabContent>
                              </Card>
                          </Col>
                          </Row>                            
                      </Container>
                  </div>
              </div>
              <OthelloFooter />
          </div>        
    </>
  );
}

export default SolucionIntegracionesTerceros;


/*CodProduc	Descripcion
Marketing	Interfaz RMS ControlData
Marketing	Interfaz RMS Smart Revenue
Marketing	Interfaz RMS ReviewPro
Marketing	Interfaz RMS FidelTour
Marketing	Interfaz RMS TrustYou
Marketing	Interfaz RMS Customer Alliance
Marketing	Interfaz RMS BeOnPrice
Marketing	Interfaz RMS JourneyTok
Marketing	Interfaz RMS BedsRevenue
Marketing	Interfaz RMS HotelLink
Marketing	Interfaz RMS Lybra
Marketing	Interfaz RMS Jaippy
Marketing	Interfaz CRM QueryTech
Marketing	Interfaz CRM Connect PushTech

CashBox	Interfaz Caj�n CashGuard
CashBox	Interfaz Caj�n CashLogic
CashBox	Interfaz Caj�n CashKeeper

Channel	Interfaz Channel Parity Rate
Channel	Interfaz Channel SiteMinder
Channel	Interfaz Channel AvailPro
Channel	Interfaz Channel RateTiger
Channel	Interfaz Channel Synergy
Channel	Interfaz Channel YieldPlanet

Dom�tica	Interfaz Tarificador Char
Dom�tica	Interfaz Cerraduras Tesa
Dom�tica	Interfaz Cerraduras Inhova

Otros	Interfaz API RoomMatik
Otros	Interfaz App Rossa Connect
Otros	Interfaz PayTef
Otros	Interfaz Lector Gesvi
Otros	Interfaz Lector Delta

Contabilidad	SAGE
Contabilidad	Contaplus
Contabilidad	A3
Contabilidad	Altai
Contabilidad	Geyce
Contabilidad	Monitor
Contabilidad	Navision
Contabilidad	VisualConta
Contabilidad	Dimoni*/
