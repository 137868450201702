import React from "react";

// reactstrap components
import {    
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Alert} from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloFooter from "../componentsothello/OthelloFooter";
import ImagenPrinc from "../assets/img/sections/austin.jpg";
import DocumentMeta from 'react-document-meta';

import { Trans, useTranslation } from 'react-i18next';

var ClickPulsado = "";

function SoportePermisos() {
    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.classList.add("soportepermisos");

        if (Estado == null || Estado == "") {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        }
        return function cleanup() {
            document.body.classList.remove("soportepermisos");
        };
    });       
    
    const [firstNameFocus, setFirstNameFocus] = React.useState("");
    const [lastNameFocus, setLastNameFocus] = React.useState("");
    const [hotelNameFocus, sethotelNameFocus] = React.useState("");
    const [cargoempleadoNameFocus, setcargoempleadoNameFocus] = React.useState("");
    const [emailFocus, setEmailFocus] = React.useState("");

    const [Estado, setEstado] = React.useState("");
    const EnviarEmailPermiso = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);

        if (ClickPulsado == "YES") {
            fetch('api/Data/NuevoPermisoSoporte', {
                method: 'post',
                body: data
            }).
                then(retorno => retorno.text().then(resultado => resultado != null && resultado == "OK" ? setEstado("OK") : setEstado(resultado)));
        }
        else Alert("Necesitamos saber que no eres un Robot");
    };

    const MontarResultadoError = () => {
        return (
            <>
                {ContactosLinks()}
                {Estado != null && Estado == "OK" ?
                    <Alert color="success">
                        <Trans i18nKey="_soportepermisoOK" />
                    </Alert>
                    :
                    <Alert color="danger">
                        <Trans i18nKey="_soportepermisoERROR" />
                    </Alert>
                }               
                </>);
    };

    const MontarCard = () => {
        return (
            <>
                <Row className="mt-5 mb-4 pt-5">
                    <Col className="ml-auto mr-auto text-center mt-5" md="8">
                        <h1 className="title">
                            <Trans i18nKey="_SoportePermisosTitle">
                            </Trans>
                        </h1>
                        <h4 className="desc">
                            {t("_SoportePermisosSubTitle")}
                        </h4>
                        Tras aceptar recibira un correo con la aceptacion de acceso segun LGPD
                    </Col>
                </Row>
                <Row>
                    <Col className="mx-auto" md="6">
                        <Card
                            className="bg-secondary p-3"
                            id="contact-form"
                            method="post"
                            role="form"
                            tag="form">
                            <CardBody>
                                <form onSubmit={EnviarEmailPermiso}>
                                    <Row>                                                                                
                                        <Col md="6">
                                            <FormGroup className={firstNameFocus}>
                                                <label>Nombre*</label>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-circle-08"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        id="NombreID"
                                                        name="nombre"
                                                        maxLength="70"
                                                        aria-label="Nombre..."
                                                        placeholder="Nombre..."
                                                        type="text"
                                                        required
                                                    ></Input>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className={lastNameFocus}>
                                                <label>Apellidos</label>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-circle-08"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        id="ApellidosID"
                                                        maxLength="70"
                                                        name="apellidos"
                                                        aria-label="Apellidos..."
                                                        placeholder="Apellidos..."
                                                        type="text"
                                                    ></Input>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className={hotelNameFocus}>
                                                <label>Hotel*</label>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-building"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        id="HotelID"
                                                        name="hotel"
                                                        maxLength="70"
                                                        aria-label="Hotel..."
                                                        placeholder="Hotel..."
                                                        type="text"
                                                        required
                                                    ></Input>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className={cargoempleadoNameFocus}>
                                                <label>Cargo en el Hotel*</label>
                                                <InputGroup className="input-group-alternative">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-paper-diploma"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        id="CargoID"
                                                        name="cargoempleado"
                                                        maxLength="70"
                                                        aria-label="Cargo en el Hotel..."
                                                        placeholder="Cargo en el Hotel..."
                                                        type="text"
                                                        required
                                                    ></Input>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup className={emailFocus}>
                                        <label style={{ color: Estado != null && Estado == "RQFALSE" ? 'red' : '' }}>Email*</label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend" >
                                                <InputGroupText>
                                                    <i className="ni ni-email-83"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input                                               
                                                id="EmailID"                                                
                                                name="email"
                                                maxLength="70"
                                                placeholder="Email..."
                                                type="text"
                                                required
                                            ></Input>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Motivo Intervencion*</label>
                                        <Input
                                            className="form-control-alternative"
                                            id="message"
                                            name="motivo"
                                            maxLength="239"
                                            rows="2"
                                            type="textarea"
                                            required
                                        ></Input>
                                    </FormGroup>                                    
                                    <Row className="justify-content-end">
                                        <Col className="text-left" md="12"><label>*(Requerido)</label></Col>
                                        <FormGroup>
                                            <Input
                                                className="form-control-alternative"
                                                id="robot"
                                                name="robot"
                                                type="checkbox"
                                                required
                                                onClick={ClickPulsado ="YES"}
                                            ></Input>
                                            <label>No soy un robot</label>
                                        </FormGroup>
                                        <Col className="text-right" md="12">
                                            <Button color="primary" type="submit">
                                                Enviar Permiso
                        </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {ContactosLinks()}
            </>
        );
    };

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | Permisos a Soporte',
        description: 'Estamos a tu disposición para ofrecerte la mejor solución para tu proyecto',
        canonical: 'https://www.millenium-soft.es/soportepermiso',         
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

    return (    
        <>
            <DocumentMeta {...meta} />
          <OthelloNavbar type="transparent" />   
          <div className="section-shaped my-0 skew-separator skew-mini">
              <div className="page-header page-header-small header-filter">
                  <div
                      className="page-header-image"
                      style={{
                          backgroundImage:
                              "url(" + ImagenPrinc + ")",
                      }}
                  ></div>
                  <Container>
                      <div className="header-body text-center mb-7">
                          <Row className="justify-content-center">
                              <Col className="px-5" lg="6" md="8" xl="7">
                                  <h1 className="text-white">Solicitud de Permisos</h1>
                                  <p className="text-lead text-white">
                                      <Trans i18nKey="_solicitudespermisosA" />
                                    </p>
                              </Col>
                          </Row>
                      </div>
                  </Container>
              </div>
          </div>          
          <div>              
              <Container fluid>
                  {Estado == null || Estado == "" || Estado == "RQFALSE" ? MontarCard() : MontarResultadoError()}                    
               </Container>              
          </div>
          <br/><br/>
          <OthelloFooter />
    </>
  );   


    function ContactosLinks() {
        return <div className="contactus-3">
            <Container className="pt-5">
                {/*<Row>
            <Col className="text-center my-5" md="12">
                <h1 className="display-1 mt-3">Contacta con Nosotros</h1>
                <h3 className="lead">
                    Estamos a su disposicion para ofrecerle la mejor solucion.
                    </h3>
            </Col>
        </Row>*/}
                <Row>
                    <Col lg="3" md="6" xs="6">
                        <div className="info info-hover">
                            <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                                <i className="ni ni-square-pin"></i>
                            </div>
                            <h4 className="info-title">{t("_direccion")}</h4>
                            <p className="description px-0">Avd. Ciudad de Barcelona, 204 1&deg;A. 28007 - Madrid</p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" xs="6">
                        <div className="info info-hover">
                            <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                                <i className="ni ni-email-83"></i>
                            </div>
                            <h4 className="info-title">Email</h4>
                            <p className="description px-0"><a href="mailto:info@millenium-soft.es">info@millenium-soft.es</a></p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" xs="6">
                        <div className="info info-hover">
                            <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                                <i className="ni ni-mobile-button"></i>
                            </div>
                            <h4 className="info-title">{t("_telefono")}</h4>
                            <p className="description px-0"><a href="tel:+34915020400">+34 91 502 04 00</a></p>
                        </div>
                    </Col>
                    <Col lg="3" md="6" xs="6">
                        <div className="info info-hover">
                            <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
                                <i className="ni ni-circle-08"></i>
                            </div>
                            <h4 className="info-title">Contacto</h4>
                            <p className="description px-0"><a href="mailto:agarcia@millenium-soft.es">Alberto Garcia</a></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>;
    }
}



export default SoportePermisos;