import React from "react";

//import { useClearCache } from "react-clear-cache";
import { useClearBrowserCache } from 'react-clear-browser-cache';

export default function OthelloVersionWeb() {
    //  const { latestVersion  } = useClearCache();

    const contextValue = useClearBrowserCache();

    return (
        <div>
            {/*latestVersion*/}
            {contextValue != null ? contextValue.latestVersion : "Sin Indicar"}
        </div>
    );
}
