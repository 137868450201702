import React from "react";

// reactstrap components
import { Card, CardImg, Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import Esquema from "../../assets/img/theme/Schema.png"

// Core Components

function OthelloHomeFeature4() {
    const { t } = useTranslation();
    return (
        <>
            <div className="section features-4">
                <Container>
                    <Row>
                        <Col className="text-center mx-auto" md="8">
                            <h1 className="display-3">{t("_Homeparnertitle")}</h1>
                            <p className="lead">{t("_HomeparnerText")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="mr-auto text-left mt-4" lg="4">
                            <Card className="bg-default shadow border-0">
                                <CardImg
                                    alt="..."
                                    src={Esquema}
                                    top
                                ></CardImg>
                                <blockquote className="card-blockquote">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="svg-bg"
                                        preserveAspectRatio="none"
                                        viewBox="0 0 583 95"
                                    >
                                        <polygon
                                            className="fill-default"
                                            points="0,52 583,95 0,95"
                                        ></polygon>
                                        <polygon
                                            className="fill-default"
                                            opacity=".2"
                                            points="0,42 583,95 683,0 0,95"
                                        ></polygon>
                                    </svg>
                                    <h4 className="display-4 text-white">{t("_Home_Right_title_integ")}</h4>
                                    <p className="lead text-italic text-white">
                                        {t("_Home_Right_descrip_integ")}
                                    </p>
                                </blockquote>
                            </Card>
                        </Col>
                        <Col className="p-sm-0" lg="7">
                            <Row>
                                <Col md="6">
                                    <a                                       
                                        href="soluciones/channel-manager"
                                    >                                    
                                    <div className="info info-hover-warning">
                                        <div className="icon icon-shape bg-warning shadow rounded-circle text-primary">
                                            <i class="fas fa-map-signs text-white"></i>
                                        </div>
                                        <h5 className="info-title">Channel Manager</h5>
                                        <p className="description opacity-8">
                                            {t("_HomeChannel")}
                                        </p>
                                        </div>
                                    </a>
                                </Col>
                                <Col md="6">
                                    <a                                
                                        href="soluciones/integraciones-terceros?info=marketing"
                                    >
                                    <div className="info info-hover-info">
                                        <div className="icon icon-shape bg-info shadow rounded-circle text-primary">
                                            <i class="fas fa-chart-line  text-white"></i>
                                        </div>
                                        <h5 className="info-title">RMS</h5>
                                        <p className="description opacity-8">
                                            {t("_HomeRMSText")}
                                        </p>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="mt-lg-4">
                                <Col md="6">
                                    <a                                      
                                        href="soluciones/integraciones-terceros?info=contabilidad"
                                    >
                                    <div className="info info-hover-danger">
                                        <div className="icon icon-shape bg-danger shadow rounded-circle text-primary">
                                            <i class="fas fa-wallet text-white"></i>
                                        </div>
                                        <h5 className="info-title">{t("_HomeContaTitle")}</h5>
                                        <p className="description opacity-8">{t("_HomeContaText")}
                                        </p>
                                        </div>
                                    </a>
                                </Col>
                                <Col md="6">
                                    <a                                     
                                        href="soluciones/integraciones-terceros?info=domotica"
                                    >
                                    <div className="info info-hover-success">
                                        <div className="icon icon-shape bg-success shadow rounded-circle text-primary">
                                            <i class="fas fa-plug text-white"></i>
                                        </div>
                                        <h5 className="info-title">Interfaces</h5>
                                        <p className="description opacity-8">{t("_HomeInterfaz")}</p>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OthelloHomeFeature4;