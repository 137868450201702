import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";

import { useTranslation } from 'react-i18next';

import apps from "../assets/img/appsmoviles.jpg";
import payothello from "../assets/img/payenmaquetado.png";
import analitycs from "../assets/img/enmaquetadoanalitycs.png";
import activitiesenmaquetado from "../assets/img/activitiesenmaquetado.png";
import checkinenmaquetado from "../assets/img/checkinenmaquetado.png";

function SolucionAppWeb()
{  
    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

  return (
    <>
          <OthelloNavbar type="transparent" />
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_appsweb" subtitulo="_appswebSubTitle" imagen={apps} conbotones={false} />
            {/*  <ProjectsSectionWeb />*/}
       {/*  <OthelloSolucionFeature7 titulo1="_appstitle1WEB" texto1="_appstitle1WEBtxt" titulo2="_appstitle2" texto2="_appstexto2" />*/}
              <OthelloSolucionFeature4Left titulo="_appwebPAyOThello" texto="_appwebPAyOThelloText" imagen={payothello} />
              <OthelloSolucionFeature4Right titulo="_appwebFastTitle" texto="_appwebFastTexto" imagen={checkinenmaquetado} />
              <OthelloSolucionFeature4Left titulo="_appwebdashboard" texto="_appwebdashboardtexto" imagen={analitycs} />
              <OthelloSolucionFeature4Right titulo="_appwebactivities" texto="_appwebactivitiestexto" imagen={activitiesenmaquetado} />
              <OthelloFooter />
          </div>        
    </>
  );
}

export default SolucionAppWeb;
