import React from "react";

// reactstrap components
import {  
    Card,
    CardBody,    
    CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";

import { Trans, useTranslation } from 'react-i18next';
 
// Core Components

function ProjectsSection() {

    const { t } = useTranslation();

    return (
        <>
            <div className="project-2 my-5">
                <Container>
                    <Row>
                        <Col className="mx-auto text-center my-5" lg="12">
                            <h3 className="display-3">{t("_tpvtitle")}</h3>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto">
                                        <i class="fas fa-hand-point-up"></i>
                                    </div>
                                </a>
                                <CardBody> 
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_tpvopc1title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_tpvopc1texto")}                                        
                                    </p>                                   
                                </CardBody>
                                
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto">
                                        <i class="fas fa-barcode"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_tpvopc2title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_tpvopc2texto")}                                        
                                    </p>                                   
                                </CardBody>
                               
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto">
                                        <i class="fas fa-tablet-alt"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_tpvopc3title")}
                                    </CardTitle>
                                    <p className="card-description">                                        
                                        <Trans i18nKey="_tpvopc3texto"
                                            components={<a />} />
                                    </p>                                    
                                </CardBody>
                               
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto">
                                        <i class="fas fa-money-check"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4"> 
                                        {t("_tpvopc4title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_tpvopc4texto")}                                        
                  </p>
                                    
                                </CardBody>
                            </Card>
                        </Col>   
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto">
                                        <i class="fas fa-bed"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_tpvopc5title")}
                  </CardTitle>
                                    <p className="card-description">                                        
                                        <Trans i18nKey="_tpvopc5texto"
                                            components={<a />} />
                  </p>
                                  
                                </CardBody>                               
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-darker shadow rounded-circle mx-auto">
                                        <i class="fas fa-cloud"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_tpvopc6title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_tpvopc6texto")}
                                    </p>
                                   
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProjectsSection;