import React, { Component } from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";


import "./assets/css/nucleo-svg.css";
import "./assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-design-system.scss?v1.0.0";

import Home from "./view/Home.js";
import Soporte from "./view/Soporte.js";
import SoportePermisos from "./view/SoportePermisos.js";
import Contacto from "./view/Contacto.js";
import EmpresaSobreNosotros from "./view/EmpresaSobreNosotros.js";
import EmpresaTrabajaConNosotros from "./view/EmpresaTrabajaConNosotros.js";
import Blog from "./view/Blog.js";
import NuestrosClientes from "./view/NuestrosClientes.js";
import SolucionOthello from "./view/SolucionOthello";
import SolucionTpv from "./view/SolucionTpv";
import SolucionBackOffice from "./view/SolucionBackOffice";
import SolucionOnetbooking from "./view/SolucionOnetbooking";
import SolucionEventos from "./view/SolucionEventos";
import SolucionAppsMoviles from "./view/SolucionAppsMoviles";
import SolucionAppWeb from "./view/SolucionAppsWeb";
import SolucionIntegracionesTerceros from "./view/SolucionIntegracionesTerceros";
import SolucionChannelManager from "./view/SolucionChannelManager";
import Privacidad from "./view/Privacidad.js";
import AvisoLegal from "./view/AvisoLegal.js";
import Cookies from "./view/Cookies.js";
import RedesKitDigital from './view/RedesKitDigital';
import LoginUser from './view/LoginUser';
import MiCuenta from './view/MiCuenta';


export default class App extends Component {
  static displayName = App.name;

  render () {
      const hist = createBrowserHistory();

      return (
          <Router history={hist}>
              <Switch>
                  <Route path="/index" render={(props) => <Home {...props} />} />   
                  { /*Empresa*/}
                  <Route path="/sobre-nosotros" render={(props) => <EmpresaSobreNosotros {...props} />} />    
                  <Route path="/trabaja-con-nosotros" render={(props) => <EmpresaTrabajaConNosotros {...props} />} />    
                  { /*soluciones*/}
                  <Route path="/soluciones/othello" render={(props) => <SolucionOthello {...props} />} />    
                  <Route path="/soluciones/terminal-punto-de-venta" render={(props) => <SolucionTpv {...props} />} />    
                  <Route path="/soluciones/back-office" render={(props) => <SolucionBackOffice {...props} />} />    
                  <Route path="/soluciones/motor-onetbooking" render={(props) => <SolucionOnetbooking {...props} />} />    
                  <Route path="/soluciones/eventos-y-salones" render={(props) => <SolucionEventos {...props} />} />    
                  <Route path="/soluciones/aplicaciones-moviles" render={(props) => <SolucionAppsMoviles {...props} />} />    
                  <Route path="/soluciones/aplicaciones-web" render={(props) => <SolucionAppWeb {...props} />} />
                  <Route path="/soluciones/kit-digital" render={(props) => <RedesKitDigital {...props} />} />
                  <Route path="/soluciones/integraciones-terceros" render={(props) => <SolucionIntegracionesTerceros {...props} />} />
                  <Route path="/soluciones/channel-manager" render={(props) => <SolucionChannelManager {...props} />} />
                  { /*Nuestro Clientes*/}
                  <Route path="/clientes-satisfechos" render={(props) => <NuestrosClientes {...props} />} />    
                  { /*Noticias*/}
                  <Route path="/blog" render={(props) => <Blog {...props} />} />    
                  { /*contacto*/}
                  <Route path="/contacto" render={(props) => <Contacto {...props} />} />    
                  { /*Soporte*/}
                  <Route path="/soporte" render={(props) => <Soporte {...props} />} />
                  <Route path="/soportepermiso" render={(props) => <SoportePermisos {...props} />} />
                  <Route path="/privacidad" render={(props) => <Privacidad {...props} />} />  
                  <Route path="/aviso-legal" render={(props) => <AvisoLegal {...props} />} />  
                  <Route path="/cookies" render={(props) => <Cookies {...props} />} />
                  <Route path="/login" render={(props) => <LoginUser {...props} />} />
                  <Route path="/micuenta" render={(props) => <MiCuenta {...props} />} />
                  <Redirect from="/" to="/index" />
              </Switch>
          </Router>
      );
  }
}


