import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import ImagenOpera from  "../../assets/img/sections/hotelopera.png";
import ImagenGandia from "../../assets/img/sections/gandiapalace.png";
import ImagenJuderia from "../../assets/img/sections/casasjuderia.png";
import ImagenVelada from  "../../assets/img/sections/palaciovelada.png";
import ImagenRegente from "../../assets/img/sections/regente.jpg";
import ImagenGVia from "../../assets/img/sections/granvia.jpg";

// Core Components

function Projects3() {
  return (
    <>
      <div className="projects-3">
        <Container>         
            <Row>
            <Col lg="4">
              <Card
                className="card-background"
                              style={{
                                  backgroundImage:
                                      "url(" + ImagenOpera + ")",
                              }}
              >
                              <CardBody className="text-center">                 
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">Hotel Opera</CardTitle>
                  </a>
                    <h6 className="desc text-white opacity-8">Madrid</h6>
                  <Button
                                      color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                                      Ver mas
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card
                className="card-background"
                style={{
                  backgroundImage:
                        "url(" + ImagenGandia + ")",
                }}
              >
                              <CardBody className="text-center">                  
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                      <CardTitle tag="h3">Hotel Gandia Palace</CardTitle>
                  </a>
                  <h6 className="desc text-white opacity-8">Gand&iacute;a</h6>
                  <Button
                    color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                   Ver mas
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card
                className="card-background"
                style={{
                  backgroundImage:
                        "url(" + ImagenJuderia + ")",
                }}
              >
                              <CardBody className="text-center">                 
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                      <CardTitle tag="h3">Las Casas de la Juderia</CardTitle>
                  </a>
                  <h6 className="desc text-white opacity-8">Sevilla</h6>
                  <Button
                    color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                                      Ver mas
                  </Button>
                </CardBody>
              </Card>
            </Col>
                  </Row>
            <Row>
                      <Col lg="4">
                          <Card
                              className="card-background"
                              style={{
                                  backgroundImage:
                                      "url(" +
                                      ImagenVelada+
                                      ")",
                              }}
                          >
                              <CardBody className="text-center">
                                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                      <CardTitle tag="h3">Hotel Palacio de los Velada</CardTitle>
                                  </a>
                                  <h6 className="desc text-white opacity-8">&Aacute;vila</h6>
                                  <Button
                                      color="danger"
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                      size="sm"
                                  >
                                      Ver mas
                  </Button>
                              </CardBody>
                          </Card>
                      </Col>
                      <Col lg="4">
                          <Card
                              className="card-background"
                              style={{
                                  backgroundImage:
                                      "url(" + ImagenRegente + ")",
                              }}
                          > 
                              <CardBody className="text-center">
                                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                      <CardTitle tag="h3">Hotel Regente</CardTitle>
                                  </a>
                                  <h6 className="desc text-white opacity-8">Madrid</h6>
                                  <Button
                                      color="danger"
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                      size="sm"
                                  >
                                      Ver mas
                  </Button>
                              </CardBody>
                          </Card>
                      </Col>
                      <Col lg="4">
                          <Card
                              className="card-background"
                              style={{
                                  backgroundImage:
                                      "url(" + ImagenGVia + ")",
                              }}
                          >
                              <CardBody className="text-center">
                                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                      <CardTitle tag="h3">Hotel Avenida Gran Via</CardTitle>
                                  </a>
                                  <h6 className="desc text-white opacity-8">Madrid</h6>
                                  <Button
                                      color="danger"
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                      size="sm"
                                  >
                                      Ver mas
                  </Button>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>        
              </Container>
              <br /><br /><br /><br />
      </div>
    </>
  );
}

export default Projects3;
