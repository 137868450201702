import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionEventosProjects2 from "../componentsothello/solucion/eventos/OthelloSolucionEventosProjects2";
import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";

import image1 from "../assets/img/theme/lucy.jpg";
import eventos from "../assets/img/eventos.jpg";
import eventos1 from "../assets/img/mesa1.jpg";
import eventos2 from "../assets/img/theme/john-hoang.jpg";

function SolucionEventos() {

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

  return (
    <>
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_eventosalones" subtitulo="_eventosheadertitle" imagen={eventos} conbotones={false} />
              <OthelloSolucionEventosProjects2 />
              <OthelloSolucionFeature7 titulo1="_eventostitle1" texto1="_eventostexto1" titulo2="_eventostitle2" texto2="_eventostexto2" />
              <OthelloSolucionFeature4Left titulo="_eventostitleinfo1" texto="_eventostextoinfo1" imagen={eventos} />
              <OthelloSolucionFeature4Right titulo="_eventostitleinfo2" texto="_eventostextoinfo2" imagen={eventos1} />
              <OthelloSolucionFeature4Left titulo="_eventostitleinfo3" texto="_eventostextoinfo3" imagen={eventos2} />
              <OthelloSolucionFeature4Right titulo="_eventostitleinfo4" texto="_eventostextoinfo4" imagen={image1} />
              <OthelloFooter />
          </div>      
    </>
  );
}

export default SolucionEventos;
