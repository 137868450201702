import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";
import DocumentMeta from 'react-document-meta';
import quienesomos from "../assets/img/quienesomos.jpg";
import calidad from "../assets/img/calidad.jpg";

import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";

import { Trans, useTranslation } from 'react-i18next';

function SobreNosotros() {

    const { t } = useTranslation();

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | Sobre nosotros',
        description: 'La tecnología puesta al servicio del sector hotelero y los conocimientos del mismo son nuestra carta de presentación. El equipo humano que forma MILLENIUM, la mejor garantía.',
        canonical: 'https://www.millenium-soft.es/soluciones/othello',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }


  return (
      <>
          <DocumentMeta {...meta} />
              <OthelloNavbar /*type="transparent" */ />
              <div className="wrapper">
                  <OthelloHeader titulo="_quiensomos" subtitulo="_quienessomosheadertitle" imagen={quienesomos} conbotones={false} />
                  <div className="project-2 my-5">
                      <Container>
                          <Row>
                              <Col className="mx-auto text-center my-5" lg="12">
                                  <h3 className="display-3">{t("_quienessomotitle")}</h3>
                                  <p className="lead">
                                      <Trans i18nKey="_quienessomostexto" />
                                  </p>
                              </Col>                              
                          </Row>                            
                      </Container>
                  </div>                  
                  <OthelloSolucionFeature7 titulo1="_quienessomostitle1" texto1="_quienessomostexto1" titulo2="_quienessomostitle2" texto2="_quienessomostexto2" />
                  <OthelloSolucionFeature4Left titulo="_quienessomostitleinfo1" texto="_quienessomostextoinfo1" imagen={calidad} />     <br /> <br />             
                  <OthelloFooter />
              </div>
          </>   
  );
}

export default SobreNosotros;
