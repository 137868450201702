import React from "react";

import { saveAs } from "file-saver";

// reactstrap components
import { Container, Row, Col, Card, CardImg, Button } from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloFooter from "../componentsothello/OthelloFooter";
import OthelloWhatsApp from "../componentsothello/OthelloWhatsApp";
import { Trans, useTranslation } from 'react-i18next';

import ImagePrinc from "../assets/img/pages/georgie.jpg";
import ImageSop2 from "../assets/img/theme/soporteblue.jpg";
import LightExe from "../assets/videos/SUPREMO CLIENTES.exe";
import DocumentMeta from 'react-document-meta';



function Soporte() {
  React.useEffect(() => {
    document.body.classList.add("soporte");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("soporte");
    };
  });
  
    const { t } = useTranslation();      


    const LlamarAppSoporte = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);

        fetch('api/Data/onClickSoporteAsistido', {
            method: 'post',
            body: data
        }).
            then(retorno => retorno.text().then(url => url != null && url != "" ? window.open(url) : ""));
    };

    const OpenFileExe = (event) =>
    {
        event.preventDefault();

        saveAs(LightExe,"supremo.exe");
        

        //fetch('https://www.millenium-soft.es/SUPREMO CLIENTES.exe', {
        //    method: 'GET',
        //    headers: {
        //        'Content-Type': 'application/pdf',
        //    },
        //})
        //    .then((response) => response.blob())
        //    .then((blob) => {
        //        // Create blob link to download
        //        const url = window.URL.createObjectURL(
        //            new Blob([blob]),
        //        );
        //        const link = document.createElement('a');
        //        link.href = url;
        //        link.setAttribute(
        //            'download',
        //            `FileName.pdf`,
        //        );

        //        // Append to html link element page
        //        document.body.appendChild(link);

        //        // Start download
        //        link.click();

        //        // Clean up and remove the link
        //        link.parentNode.removeChild(link);
        //    });


       // window.open("https://demos.creative-tim.com/material-dashboard-pro-react/#/admin/buttons", '_blank')
    };

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | Soporte',
        description: '24x7 los 365 días del año a tu disposición para resolver cualquier emergencia',
        canonical: 'https://www.millenium-soft.es/soporte',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

  return (
      <>
          <DocumentMeta {...meta} />
          <OthelloNavbar type="transparent" DesdeSoporte="true"/>
          <div className="section-shaped my-0 skew-separator skew-mini">
              <div className="page-header page-header-small header-filter">
                  <div
                      className="page-header-image"
                      style={{
                          backgroundImage:
                              "url(" + ImagePrinc + ")",
                      }}
                  ></div>
                  <Container>
                      <div className="header-body text-center mb-7">
                          <Row className="justify-content-center">
                              <Col className="px-5" lg="6" md="8" xl="7">
                                  <h1 className="text-white">Soporte</h1>
                                  <p className="text-lead text-white">
                                      {t("_soportesubtitle")}
                                    </p>
                              </Col>
                          </Row>
                      </div>
                  </Container>
              </div>
          </div>
          <div className="section features-4">
             {/* <form onSubmit={LlamarAppSoporte}>*/}
              <Container>               
                  <Row className="align-items-center">
                      <Col className="mr-auto text-left mt-4" lg="4">
                          <Card className="bg-default shadow border-0">
                              <CardImg
                                  alt="..."
                                  src={ImageSop2}
                                  top
                              ></CardImg>
                              <blockquote className="card-blockquote">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="svg-bg"
                                      preserveAspectRatio="none"
                                      viewBox="0 0 583 95"
                                  >
                                      <polygon
                                          className="fill-default"
                                          points="0,52 583,95 0,95"
                                      ></polygon>
                                      <polygon
                                          className="fill-default"
                                          opacity=".2"
                                          points="0,42 583,95 683,0 0,95"
                                      ></polygon>
                                  </svg>
                                  <h4 className="display-4 text-white">{t("_soporteblueRight")}</h4>
                                  <p className="lead text-italic text-white">
                                      {t("_soportebluedescrip")}
                                    </p>
                              </blockquote>
                          </Card>
                      </Col>
                      <Col className="p-sm-0" lg="7">
                          <Row>
                              <Col md="12">
                                  
                                  <h2 className="info-title">{t("_soporteblueRight")}</h2>
                                      <p className="description opacity-8">
                                      {t("_soportedown")}
                                        </p>                                  
                              </Col>                              
                          </Row>
                          <Row className="row-input">                              
                              <Col className="pl-lg-0" sm="6" xs="12">
                                  <Button block color="primary" onClick={OpenFileExe}>
                                      Conectar
                                  </Button>                                  
                              </Col>                             
                          </Row>
                          <br/>
                          <Row>
                              <Col md="12">                                 
                                  <p className="description opacity-8">
                                      {t("_soportepermiso")}
                                  </p>
                              </Col>
                          </Row>
                          <Row className="row-input">                              
                              <Col className="pl-lg-0" sm="6" xs="12">
                                  <Button block color="warning" type="button" href="/soportepermiso">
                                      Dar Permiso
                                  </Button>
                              </Col>
                          </Row>
                          <Row className="mt-lg-4">
                              <Col md="6">
                                  <div className="info info-hover-danger">
                                      <div className="icon icon-shape bg-danger shadow rounded-circle text-primary">
                                          <i className="ni ni-user-run text-white"></i>
                                      </div>
                                      <h5 className="info-title">{ t("_soporteemergencia")}</h5>
                                      <p className="description opacity-8">
                                          <Trans i18nKey="_soportemovilguardia"
                                              components={<a />}>
                                          </Trans>
                                          
                    </p>
                                  </div>
                              </Col>
                              <Col md="6">
                                  <div className="info info-hover-success">
                                      <div className="icon icon-shape bg-success shadow rounded-circle text-primary">
                                          <i class="fas fa-clock text-white"></i>
                                      </div>
                                      <h5 className="info-title">{t("_soporteTitle")}</h5>
                                      <p className="description opacity-8">                                          
                                          <Trans i18nKey="_soportecall"
                                              components={<a/>}>
                                          </Trans> 
                    </p>
                                  </div>
                              </Col>
                          </Row>
                          <Row className="row-input"> 
                              <Col className="pl-lg-0 mt-4" sm="6"  xs="12">
                                  <OthelloWhatsApp block={true} textoboton="Solo Emergencias" />
                              </Col>
                          </Row>
                      </Col>
                  </Row>
                  </Container>
              {/*</form>*/}              
          </div>  
          <div>
              <Container fluid>
                  <Row className="mt-5 mb-4 pt-5">
                      <Col className="ml-auto mr-auto text-center mt-5" md="6">
                          <h1 className="title">
                              Soporte Othello
                          </h1>
                          <h4 className="desc">
                              <Trans i18nKey="_soportedescrip" />
                          </h4>
                      </Col>
                  </Row>
              </Container>
          </div>
          <br /><br />
          <OthelloFooter />
    </>
  );
}

export default Soporte;
