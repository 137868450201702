import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";

import image1 from "../assets/img/sections/unsplashs.jpg";
import extras from "../assets/img/Extras.jpg";
import image2 from "../assets/img/reservaweb.png";
import imageDigital from "../assets/img/logodigitalizadores.png";
import image3 from "../assets/img/faces/alejandro-escamilla.jpg";
import { Container, Row, Col, Card } from "reactstrap";
import DocumentMeta from 'react-document-meta';

function RedesKitDigital() {

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | Subvenciones Kit digital',
        description: 'La oportunidad de impulsar y digitalizar tu empresa',
        canonical: 'https://www.millenium-soft.es/soluciones/kit-digital',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

  return (
      <>
          <DocumentMeta {...meta} />
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_kitdigital" subtitulo="_kitdigitaltitle" imagen={image1} conbotones={false} />
              <div className="section">
                  <Container>                     
                      <Row>
                          <Col md="12">                             
                              <Card className="card-product card-plain">
                                  <div className="card-image">
                                      <a href="#" onClick={(e) => e.preventDefault()}>
                                          <img alt="..." src={imageDigital}></img>
                                      </a>
                                  </div>
                              </Card>
                          </Col>
                      </Row>
                  </Container>
              </div>
              <OthelloSolucionFeature4Right titulo="_comercioelectronicokitdigital" texto="_textocomercioelectronicokitdigital" imagen={image2} />              
              <OthelloSolucionFeature4Left titulo="_facturaelectronicakitdigital" texto="_textofacturaelectronicakitdigital" imagen={image3} />
              <OthelloSolucionFeature4Right titulo="_gestionprocesoskitdigital" texto="_textogestionprocesoskitdigital" imagen={extras} />
              <OthelloSolucionFeature4Left titulo="_gestionclienteskitdigital" texto="_textogestionclienteskitdigital" imagen={extras} />
              <OthelloFooter />
      </div>
    </>
  );
}

export default RedesKitDigital;
