import React from "react";

// reactstrap components
import { 
    Card,
    CardBody,    
    CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";

import { Trans, useTranslation } from 'react-i18next';

// Core Components

function ProjectsSection() {

    const { t } = useTranslation();

    return (
        <>
            <div className="project-2 my-5">
                <Container>
                    <Row>
                        <Col className="mx-auto text-center my-5" lg="12">
                            <h3 className="display-3">{t("_eventostitle")}</h3>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto">
                                        <i class="fas fa-chair"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_eventosopc1title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_eventosopc1texto")}                                        
                                    </p>                                   
                                     </CardBody>
                                {/*<CardFooter>
                                    <Button
                                        className="text-secondary"
                                        color="link"
                                        type="button"
                                    >
                                        <i className="ni ni-glasses-2"></i>
                      Check out now
                    </Button>
                                </CardFooter> */}
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto">
                                        <i class="fas fa-music"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_eventosopc2title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_eventosopc2texto")}                                        
                                    </p>                                   
                                </CardBody>
                                {/* <CardFooter>
                                    <Button
                                        className="text-secondary"
                                        color="link"
                                        type="button"
                                    >
                                        <i className="ni ni-glasses-2"></i>
                      Check out now
                    </Button>
                                </CardFooter>*/}
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto">
                                        <i class="fas fa-people-carry"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_eventosopc3title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_eventosopc3texto")}                                        
                                    </p>                                    
                                </CardBody>
                                {/*<CardFooter>
                                    <Button
                                        className="text-secondary"
                                        color="link"
                                        type="button"
                                    >
                                        <i className="ni ni-glasses-2"></i>
                      Check out now
                    </Button>
                                </CardFooter>*/}
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col lg="4" md="4">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto">
                                        <i class="fas fa-users"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4"> 
                                        {t("_eventosopc4title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        <Trans i18nKey="_eventosopc4texto" components={<br />} />
                  </p>
                                    {/*  <CardFooter>
                                        <Button
                                            className="text-secondary"
                                            color="link"
                                            type="button"
                                        >
                                            <i className="ni ni-glasses-2"></i>
                                          Check out now
                                        </Button>
                                    </CardFooter>*/}
                                </CardBody>
                            </Card>
                        </Col>   
                        <Col lg="4" md="4">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto">
                                        <i class="fas fa-utensils"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_eventosopc5title")}
                  </CardTitle>
                                    <p className="card-description">                                        
                                        <Trans i18nKey="_eventosopc5texto" components={<br/>} />
                  </p>
                                    {/* <CardFooter>
                                        <Button
                                            className="text-secondary"
                                            color="link"
                                            type="button"
                                        >
                                            <i className="ni ni-glasses-2"></i>
                      Check out now
                    </Button>
                                    </CardFooter>*/}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="4">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto">
                                        <i class="fas fa-clipboard-list"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_eventosopc6title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_eventosopc6texto")}
                                    </p>
                                    {/*  <CardFooter>
                                        <Button
                                            className="text-secondary"
                                            color="link"
                                            type="button"
                                        >
                                            <i className="ni ni-glasses-2"></i>
                      Check out now
                    </Button>
                                    </CardFooter>*/}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProjectsSection;