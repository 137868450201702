import React from "react";

// reactstrap components
import {
  Button,    
} from "reactstrap";

import { useTranslation } from 'react-i18next';

// Core Components

function OthelloWhatsApp({ ...props }) {

    const { soloicono, block, literaltextoboton, textoboton, telefono, mensaje } = props;

    const { t } = useTranslation();

    let telefonoFinal = telefono != null && telefono != "" ? telefono : "915020400";
    let mensajeFinal = mensaje != null && mensaje != "" ? mensaje : "Puedes ayudarme";


    let textoFinal = t("_enviamensaje");

    if (soloicono)
        textoFinal = "";
    else {
        if (literaltextoboton != null && literaltextoboton != "")
            textoFinal = t(literaltextoboton);
        else if (textoboton != null && textoboton != "")
            textoFinal = textoboton;
    }

    let url = "https://wa.me/" + telefonoFinal + "?text=" + mensajeFinal;

  return (
      <>
          <Button block={block != null ? block: false } target="_blank" style={{backgroundColor: "#25d366", color: "#FFF"}} name="button" type="button" href={url}><i class="fab fa-whatsapp"></i> {textoFinal}</Button>
    </>
  );
}

export default OthelloWhatsApp;

 //<div>
          //    <a href={url} target="_blank" style={{
          //        display: "inline-block", backgroundColor: "#25d366", color: "#FFF", padding: "10px 20px", borderRadius: "5px", textAlign: "center", textDecoration: "none", fontSize: "16px"
          //    }}>
          //        <i class="fab fa-whatsapp"></i> {textoFinal}
          //    </a>
          //</div>
