import React from "react";
import { Input, Badge } from "reactstrap";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Pagination from '@mui/material/Pagination';

const Table6 = () => {
    const data = [
        { id: 1, name: 'Abritel', url: '', activo: true },
        { id: 2, name: 'ABSBooking', url: '', activo: true },
        { id: 3, name: 'Agoda', url: '', activo: true },
        { id: 4, name: 'AirAsia', url: '', activo: true },
        { id: 5, name: 'Airbnb', url: '', activo: true },
        { id: 6, name: 'AltoVita', url: '', activo: true },
        { id: 7, name: 'ApartmentsOnline', url: '', activo: true },
        { id: 8, name: 'b2b.ostrovok.ru', url: '', activo: true },
        { id: 9, name: 'bag2bag', url: '', activo: true },
        { id: 10, name: 'beachhuts.com', url: '', activo: true },
        { id: 11, name: 'Bedandbreakfast.eu', url: '', activo: true },
        { id: 12, name: 'Belhotel', url: '', activo: true },
        { id: 13, name: 'BookaBach', url: '', activo: true },
        { id: 14, name: 'Bookeasy', url: '', activo: true },
        { id: 15, name: 'Booking.com', url: '', activo: true },
        { id: 16, name: 'Booking247.com', url: '', activo: true },
        { id: 17, name: 'Bookit', url: '', activo: true },
        { id: 18, name: 'bravofly', url: '', activo: true },
        { id: 19, name: 'Britain�s Finest', url: '', activo: true },
        { id: 20, name: 'BungalowSpecials', url: '', activo: true },
        { id: 21, name: 'Camping Vision', url: '', activo: true },
        { id: 22, name: 'CheapTickets', url: '', activo: true },
        { id: 23, name: 'Cleartrip', url: '', activo: true },
        { id: 24, name: 'Club Mahindra', url: '', activo: true },
        { id: 25, name: 'CRED', url: '', activo: true },
        { id: 26, name: 'Decolar', url: '', activo: true },
        { id: 27, name: 'Despegar', url: '', activo: true },
        { id: 28, name: 'Dida', url: '', activo: true },
        { id: 29, name: 'Dorms.com', url: '', activo: true },
        { id: 30, name: 'EaseMyTrip', url: '', activo: true },
        { id: 31, name: 'eBookers', url: '', activo: true },
        { id: 32, name: 'eDreams', url: '', activo: true },
        { id: 33, name: 'Entertainment Book', url: '', activo: true },
        { id: 34, name: 'Expedia', url: '', activo: true },
        { id: 35, name: 'Eztravel', url: '', activo: true },
        { id: 36, name: 'FerienparkSpecials', url: '', activo: true },
        { id: 37, name: 'getaroom', url: '', activo: true },
        { id: 38, name: 'Getout Travel', url: '', activo: true },
        { id: 39, name: 'Go Voyages', url: '', activo: true },
        { id: 40, name: 'Go-MMT', url: '', activo: true },
        { id: 41, name: 'Goibibo', url: '', activo: true },
        { id: 42, name: 'Halalbooking', url: '', activo: true },
        { id: 43, name: 'HolidayParkSpecials', url: '', activo: true },
        { id: 44, name: 'Hoo', url: '', activo: true },
        { id: 45, name: 'Hoojoozat.com', url: '', activo: true },
        { id: 46, name: 'Hooroo', url: '', activo: true },
        { id: 47, name: 'Hostelhunter.com', url: '', activo: true },
        { id: 48, name: 'Hostelsclub', url: '', activo: true },
        { id: 49, name: 'HostelWorld', url: '', activo: true },
        { id: 50, name: 'Hotelcard AG*', url: '', activo: true },
        { id: 51, name: 'Hoteldekho', url: '', activo: true },
        { id: 52, name: 'Hotelrip', url: '', activo: true },
        { id: 53, name: 'Hotels.com', url: '', activo: true },
        { id: 54, name: 'Hotelspecials', url: '', activo: true },
        { id: 55, name: 'HotelTonight', url: '', activo: true },
        { id: 56, name: 'Hotusa', url: '', activo: true },
        { id: 57, name: 'Hotwire', url: '', activo: true },
        { id: 58, name: 'HRS', url: '', activo: true },
        { id: 59, name: 'iBook', url: '', activo: true },
        { id: 60, name: 'iescape', url: '', activo: true },
        { id: 61, name: 'Inhores', url: '', activo: true },
        { id: 62, name: 'IRCTC', url: '', activo: true },
        { id: 63, name: 'jetcost', url: '', activo: true },
        { id: 64, name: 'Jetstar', url: '', activo: true },
        { id: 65, name: 'Jumpon', url: '', activo: true },
        { id: 66, name: 'Lastminute.com', url: '', activo: true },
        { id: 67, name: 'Luxenomad', url: '', activo: true },
        { id: 68, name: 'MakeMyTrip', url: '', activo: true },
        { id: 69, name: 'Mandira Travel*', url: '', activo: true },
        { id: 70, name: 'Monasteries.com', url: '', activo: true },
        { id: 71, name: 'Mr & Mrs Smith', url: '', activo: true },
        { id: 72, name: 'need it now', url: '', activo: true },
        { id: 73, name: 'not1night', url: '', activo: true },
        { id: 74, name: 'Odigeo', url: '', activo: true },
        { id: 75, name: 'Offpeakluxury', url: '', activo: true },
        { id: 76, name: 'Opodo', url: '', activo: true },
        { id: 77, name: 'Orbitz', url: '', activo: true },
        { id: 78, name: 'Ostrovok', url: '', activo: true },
        { id: 79, name: 'Otelz', url: '', activo: true },
        { id: 80, name: 'Pan Pacific', url: '', activo: true },
        { id: 81, name: 'Pitchup', url: '', activo: true },
        { id: 82, name: 'Prestigia', url: '', activo: true },
        { id: 83, name: 'Qantas', url: '', activo: true },
        { id: 84, name: 'Rakuten Travel', url: '', activo: true },
        { id: 85, name: 'Ratehawk', url: '', activo: true },
        { id: 86, name: 'Renthia', url: '', activo: true },
        { id: 87, name: 'Robinhood Travel', url: '', activo: true },
        { id: 88, name: 'Roibos', url: '', activo: true },
        { id: 89, name: 'Rumbo', url: '', activo: true },
        { id: 90, name: 'Silver Door Apartments', url: '', activo: true },
        { id: 91, name: 'Situ', url: '', activo: true },
        { id: 92, name: 'Smartie', url: '', activo: true },
        { id: 93, name: 'SpeedyBooker', url: '', activo: true },
        { id: 94, name: 'StayingAt', url: '', activo: true },
        { id: 95, name: 'Stayz', url: '', activo: true },
        { id: 96, name: 'TabletHotels', url: '', activo: true },
        { id: 97, name: 'The Travel Trade Group*', url: '', activo: true },
        { id: 98, name: 'Tiket.com', url: '', activo: true },
        { id: 99, name: 'tobook.com', url: '', activo: true },
        { id: 100, name: 'TOMAS', url: '', activo: true },
        { id: 101, name: 'Travelguru', url: '', activo: true },
        { id: 102, name: 'Travellink', url: '', activo: true },
        { id: 103, name: 'Travelocity', url: '', activo: true },
        { id: 104, name: 'Traveloka', url: '', activo: true },
        { id: 105, name: 'TravelStay', url: '', activo: true },
        { id: 106, name: 'Travepic', url: '', activo: true },
        { id: 107, name: 'Trip.com', url: '', activo: true },
        { id: 108, name: 'TripFactory', url: '', activo: true },
        { id: 109, name: 'Tripjack', url: '', activo: true },
        { id: 110, name: 'UniversityRooms', url: '', activo: true },
        { id: 111, name: 'via.com', url: '', activo: true },
        { id: 112, name: 'villa-finder', url: '', activo: true },
        { id: 113, name: 'volagratis', url: '', activo: true },
        { id: 114, name: 'VRBO', url: '', activo: true },
        { id: 115, name: 'weg.de', url: '', activo: true },
        { id: 116, name: 'Withinearth', url: '', activo: true },
        { id: 117, name: 'World-Blue', url: '', activo: true },
        { id: 118, name: 'Wotif', url: '', activo: true },
        { id: 119, name: 'Yatra', url: '', activo: true },
        { id: 120, name: 'Yuktravel', url: '', activo: true },
        { id: 121, name: 'Zenhotels', url: '', activo: true },

    ];

    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState("");
    const [pagesize, setPageSize] = React.useState(10);

    const handlePageChange = (e, pageNumber) => {
        setPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    let countpage = Math.ceil(data.length / pagesize);

    let pagefinal = search != null && search != "" ? 1 : page;

    return (
        <>
            <Grid container spacing={2} alignItems="center" mt={2} mb={2}>
                <Grid item xs={6} sm={8} lg={9}>
                </Grid>
                <Grid item xs={6} sm={4} lg={3} pl={0.5}>
                    <Input placeholder="Buscar" value={search} onChange={handleSearchChange} width='100%' />
                </Grid>
            </Grid>
            <div className="table" mt={5}>
                <Table responsive striped>
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center">#</th>
                            <th>Service</th>
                            <th>Website</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).slice((pagefinal - 1) * pagesize, pagefinal * pagesize).map((item, index) => (
                            <tr style={{ backgroundColor: index % 2 == 0 ? "#fafbfc" : "white" }}>
                                <td className="text-center">{item.id}</td>
                                <td>{item.name}</td>
                                <td>
                                {/*<a*/}
                                {/*    target={item.url != "" ? "_blank" : "#"}*/}
                                {/*    href={'#'}*/}
                                {/*    rel="noreferrer"*/}
                                {/*>*/}
                                    Visite Website
                               {/* </a>*/}
                                </td>
                                <td >
                                    <Badge className="badge-dot mr-4">
                                        {item.activo ? <i className="bg-success"></i> : <i className="bg-danger"></i>}
                                        {item.activo ? <span className="status">Activo</span> : <span className="status">En Proceso</span>}
                                    </Badge>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
            >
                <Pagination color="primary"
                    activePage={page}
                    count={countpage}
                    pageSize={pagesize}
                    onChange={handlePageChange}
                        />
                </Grid>            
        </>
    );
};

export default Table6;