import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
    Container, 
} from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import avisolegal from "../assets/img/trabajanosotros.jpg";

import { useTranslation } from 'react-i18next';

function Cookies() {
    
    React.useEffect(() => {
        document.body.classList.add("home-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("home-page");
        };
    });

    return (
        <>
            <>
                <OthelloNavbar /*type="transparent" */ />
                <div className="wrapper">
                    <OthelloHeader titulo="_cookiesheader" subtitulo="" imagen={avisolegal} conbotones={false} />
                    <div className="project-2 my-5">
                        <Container>
                   
                   
                   
                        <div class="cookies-page">
                            <p>Una cookie es un peque&ntilde;o fragmento de texto que los sitios web que visitas env&iacute;an al navegador y que permite que el sitio web recuerde informaci&oacute;n sobre tu visita, como tu idioma preferido y otras opciones, lo que puede facilitar tu pr&oacute;xima visita y hacer que el sitio te resulte m&aacute;s &uacute;til. Las cookies desempe&ntilde;an un papel muy importante, ya que sin ellas el uso de la Web ser&iacute;a una experiencia mucho m&aacute;s frustrante.</p>

                            <h2>Tipos de cookies que utiliza este sitio web</h2>
                            <p>Las cookies las utilizamos con diversos fines, entre los que se incluyen recordar tus preferencias de idioma, contar el n&uacute;mero de visitas que recibimos, ayudar a registrarte en nuestros servicios y proteger tus datos personales.</p>
                            <p>Una parte o la totalidad de las cookies identificadas a continuaci&oacute;n se pueden almacenar en tu navegador. En la mayor&iacute;a de los casos el contenido de las cookies est&aacute; encriptado para mayor seguridad y privacidad del usuario.</p>

                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th >Categor&iacute;a de uso</th>
                                        <th>Ejemplo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td><p class="category">Preferencias</p></td>
                                        <td>
                                            <p>Estas cookies permiten que nuestros sitios web recuerden informaci&oacute;n que cambia el aspecto o el comportamiento del sitio como, por ejemplo, tu idioma preferido. Estas cookies tambi&eacute;n nos permiten mostrarte las fechas y horas en tu franja horaria actual.</p>
                                            <p>Todos los usuarios que accedan al sitio tendr&aacute;n una cookie "language", por ejemplo, especificando tu idioma preferido para ver las p&aacute;ginas.</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><p class="category">Seguridad</p></td>
                                        <td>
                                            <p>Utilizamos cookies de seguridad para autenticar usuarios, evitar el uso fraudulento de credenciales de inicio de sesi&oacute;n y proteger los datos de usuarios frente a terceros no autorizados.</p>
                                            <p>Tambi&eacute;n usamos estas cookies para firmar los env&iacute;os de formularios y evitar que ataquen nuestra infraestructura y puedan comprometer los datos que env&iacute;as.</p>
                                            <p>Un ejemplo de este tipo de cookies ser&iacute;a el c&oacute;digo que tienes en "XSRF-TOKEN".</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><p class="category">Procesos</p></td>
                                        <td>
                                            <p>Las cookies de procesos permiten el funcionamiento del sitio web y ofrecen servicios esperados por el usuario que accede al sitio web. Por ejemplo permiten el acceso a &aacute;reas seguras del sitio web o el envio de formularios de contacto.</p>
                                            <p>Sin estas cookies el sitio web no puede funcionar de forma adecuada.</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><p class="category">Publicidad</p></td>
                                        <td>
                                            <p>Usamos las cookies para hacer la publicidad m&aacute;s atrayente a los usuarios y m&aacute;s &uacute;til a los editor y anunciantes. Algunos usos comunes son seleccionar los anuncios en base a lo que un usuario considera relevante; mejorar la informaci&oacute;n sobre el rendimiento de las campa&ntilde;as; y evitar que se muestren anuncios a usuarios que ya los han visto.</p>
                                            <p>Tambi&eacute;n se usan las cookies para que terceras partes te muestren anuncios en internet basados en las interacciones que tengas con nuestro sitio web. Usamos a una tercera empresa (Google) para almacenar y procesar esta informaci&oacute;n a trav&eacute;s de sus herramientas de remarketing y de seguimiento de conversiones.</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><p class="category">Estado de la sesi&oacute;n</p></td>
                                        <td>
                                            <p>Los sitios web suelen recopilar informaci&oacute;n sobre la forma en la que los usuarios interact&uacute;an con un sitio web. Esta informaci&oacute;n nos permite, por ejemplo, identificarte como usuario o saber qu&eacute; mensajes de error has recibido de determinadas p&aacute;ginas.</p>
                                            <p>Por ejemplo usamos una cookie denominada "SID" que nos permite identificar el ID de la cuenta de un usuario.</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><p class="category">Anal&iacute;ticas</p></td>
                                        <td>
                                            <p>Tambi&eacute;n usamos herramientas de anal&iacute;ticas que nos ayudan a entender el modo en el que los visitantes interact&uacute;an con el sitio web. Dichas herramientas pueden utilizar un conjunto de cookies para recopilar informaci&oacute;n e informar de las estad&iacute;sticas de uso de los sitios web sin identificar personalmente a los visitantes.</p>
                                                <p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" rel="noreferrer">M&aacute;s informaci&oacute;n sobre las cookies de Google Analytics e informaci&oacute;n sobre la privacidad</a></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Revocaci&oacute;n y eliminaci&oacute;n de cookies</h2>
                            <p>Si lo deseas puedes eliminar las cookies instaladas en tu equipo mediante la configuraci&oacute;n de las opciones del navegador. A continuacion aparecen algunos navegadores comunes. Si el tuyo no est&aacute; en la lista consulta el manual de ayuda oficial donde seguramente constar&aacute; c&oacute;mo realizar esta acci&oacute;n.</p>
                            <ul>
                                    <li>rel="noreferrer"
                                        para m&aacute;s informaci&oacute;n sobre el navegador Google Chrome <a href="https://support.google.com/chrome/answer/95582?hl=en" target="_blank" rel="noreferrer">pulsa aqu&iacute;</a>
                                </li>
                                <li>
                                        para m&aacute;s informaci&oacute;n sobre el navegador Mozilla Firefox <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noreferrer">pulsa aqu&iacute;</a>
                                </li>
                                <li>
                                        para m&aacute;s informaci&oacute;n sobre el navegador Apple Safari <a href="http://support.apple.com/kb/PH11913" target="_blank" rel="noreferrer">pulsa aqu&iacute;</a>
                                </li>
                                <li>
                                        para m&aacute;s informaci&oacute;n sobre el navegador Windows Internet Explorer <a href="http://windows.microsoft.com/en-us/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noreferrer">pulsa aqu&iacute;</a>
                                </li>
                                <li>
                                        para m&aacute;s informaci&oacute;n sobre el navegador Opera <a href="http://help.opera.com/Windows/11.50/en/cookies.html" target="_blank" rel="noreferrer">pulsa aqu&iacute;</a>
                                </li>
                            </ul>

                            <h2>Terceros prestadores de servicios</h2>
                            <p>Algunas de nuestras cookies las procesan terceros que hemos contratado para la prestaci&oacute;n de alg&uacute;n servicio concreto, como es el servicio de mapas o de tours virtuales o los widgets de reservas. A continuaci&oacute;n aparecen los enlaces a las distintas pol&iacute;ticas de cookies de cada uno que ser&aacute;n aplicables en cuanto en tanto usemos sus servicios.</p>

                            <ul>
                                    <li><a href="https://www.google.es/intl/es/policies/technologies/cookies/" target="_blank" rel="noreferrer">Google</a></li>
                            </ul>
                        </div>
                        </Container>
                    </div>
                    <br /><br /><br />
                    <OthelloFooter />
                </div>
            </>
        </>
    );
}

export default Cookies;