import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './i18n.js';

import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
        <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
            <App />
        </ClearBrowserCacheBoundary>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

