import React from "react";

// reactstrap components
import {  Card, CardBody, Container, Row, Col } from "reactstrap";


import { Trans, useTranslation } from 'react-i18next';
import ImageFondo from "../../assets/img/ill/p31.svg";

// Core Components

function OthelloSolucionFeature7({ ...props}) {

    const { t } = useTranslation();

    const { titulo1, titulo2, texto1, texto2 } = props;

    return ( 
        <>
            <div
                className="section features-3 my-5"
                style={{
                    backgroundImage: "url(" + ImageFondo + ")",
                }}
            >
                <Container>                 
                    <Row className="justify-content-center mt-5">
                        <Col lg="12">
                            <Row className="row-grid">
                                <Col lg="6">
                                    <Card className="card-lift--hover shadow border-0">
                                        <CardBody className="py-5">
                                            <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                                                <i className="ni ni-check-bold"></i>
                                            </div>
                                            <h5 className="text-warning text-uppercase">
                                                {t(titulo1)}
                      </h5>
                                            <p className="card-description">
                                                <Trans i18nKey={texto1} />                                
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card className="card-lift--hover shadow border-0">
                                        <CardBody className="py-5">
                                            <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                                                <i className="ni ni-istanbul"></i>
                                            </div>
                                            <h5 className="text-success text-uppercase">
                                                {t(titulo2)}
                      </h5>
                                            <p className="card-description">
                                                <Trans i18nKey={texto2} />                            
                      </p>                                           
                                        </CardBody>
                                    </Card>
                                </Col>                               
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OthelloSolucionFeature7;