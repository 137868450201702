import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

// Core Components

function OthelloHomeFeature2() {

    const { t } = useTranslation();
    return (
        <>
            <div className="section features-2">
                <Container>
                    <Row className="align-items-center">
                        <Col className="mr-auto text-left" lg="4" md="8">
                            <div className="pr-md-5">
                                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                                    <i class="fas fa-hotel"></i>
                                </div>
                                <h3> {t("_HomeRightBold_Top")}</h3>
                                <p> {t("_HomeRightTop")}
                                </p>
                                <ul className="list-unstyled mt-5">
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="primary">
                                                    <i class="fas fa-calendar"></i>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{t("_HomeRightTOPA")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="primary">
                                                    <i class="fas fa-user-cog"></i>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{t("_HomeRightTOPB")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="primary">
                                                    <i class="fas fa-sync"></i>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{t("_HomeRightTOPC")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col className="pl-md-0" lg="8" md="12">
                            <Row>
                                <Col lg="4" md="4">
                                    <div className="info text-left bg-info shadow">
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            <i className="ni ni-satisfied text-info"></i>
                                        </div>
                                        <h5 className="info-title text-white">{t("_HomeLeftBlue")}</h5>
                                        <p className="description">
                                            {t("_HomeLeftBlueText")}
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4" md="4">
                                    <div className="info text-left bg-danger info-raised shadow">
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            <i className="ni ni-palette text-danger"></i>
                                        </div>
                                        <h5 className="info-title text-white">{t("_HomeLeftPinkCenter")}</h5>
                                        <p className="description">{t("_HomeLeftPinkText")}
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4" md="4">
                                    <div className="info text-left bg-default shadow">
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            <i className="ni ni-spaceship text-default"></i>
                                        </div>
                                        <h5 className="info-title text-white">{t("_HomeRightBlueDark")}</h5>
                                        <p className="description">{t("_HomeRightBlueText")}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4">
                                    <div className="info text-left bg-primary shadow">
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            <i class="fas fa-graduation-cap text-primary"></i>
                                        </div>
                                        <h5 className="info-title text-white">{t("_HomeLeftBotton")}</h5>
                                        <p className="description">{t("_HomeleftBottonText")}
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4" md="4">
                                    <div className="info text-left bg-warning info-raised shadow">
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            <i class="fas fa-headset text-warning"></i>
                                        </div>
                                        <h5 className="info-title text-white">{t("_HomeOrangeCenter")}</h5>
                                        <p className="description">{t("_HomeOrangeCenterText")}
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4" md="4">
                                    <div className="info text-left bg-success shadow">
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                            <i class="fas fa-comments text-success"></i>
                                        </div>
                                        <h5 className="info-title text-white">{t("_HomeRightGreen")}</h5>
                                        <p className="description">{t("_HomeRightGreenText")}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OthelloHomeFeature2;