import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";
import Table6 from "../components/tables/Table6";

//Channel
import agoda from "../assets/img/channels/agoda.jpg";
import airbnb from "../assets/img/channels/airbnb.jpg";
import bookingcom from "../assets/img/channels/bookingcom.jpg";
import expedia from "../assets/img/channels/expedia.jpg";
import hotelbed from "../assets/img/channels/hotelbed.jpg";
import hotusa from "../assets/img/channels/hotusa.jpg";


import apps from "../assets/img/appsmoviles.jpg";

import { useLocation } from "react-router-dom";

import { Trans, useTranslation } from 'react-i18next';

import {   
    Container,    
    Row,
    Col,
    Card,
    Button
} from "reactstrap";


function SolucionIntegracionesTerceros() {

    const { search } = useLocation();
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(true);
    const [openallotas, setopenallotas] = React.useState(false);

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() =>
    {       
        setLoading(false);
    }, [loading]);

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

    function getDataChannels()
    {
        let channels = [];
        channels.push({ imagen: bookingcom, url: "" });
        channels.push({ imagen: airbnb, url: "" });
        channels.push({ imagen: expedia, url: "" });
        channels.push({ imagen: agoda, url: "" });
        channels.push({ imagen: hotusa, url: "" });
        channels.push({ imagen: hotelbed, url: "" });       

        return channels;
    }
  

    function renderSlideOpcion(imagenes)
    {
        let imagenesfinal = [];

        if (imagenes != null && imagenes.length > 0)
        {
            let imagenesrow = [];
            let index = 0;
            let indexTotal = 0;
            let total = imagenes.length;

            imagenes.forEach(x =>
            {             
                imagenesrow.push(
                    <Col md="4">
                        <div style={{
                            textAlign: 'center',
                            padding: '5px 5px',
                            marginBottom:20,
                            height: 150,
                            /* Center vertically and horizontally */
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <a
                                target={x.url != "" ? "_blank" : ""}
                                href={x.url}
                                rel="noreferrer"
                            >
                            <img
                                style={{ padding: 5, objectFit: "fill", maxHeight: 150 }}
                                alt="..."
                                width="100%"                               
                                src={x.imagen}
                                ></img>
                            </a>
                        </div>
                    </Col>);

                index = index + 1;
                indexTotal = indexTotal + 1;

                if (index == 3 || total == indexTotal)
                {                                                            
                        imagenesfinal.push(
                        <Row>
                            {imagenesrow}
                        </Row>
                   );

                    //limpio los valores
                    index = 0;
                    imagenesrow = [];
                }
            });
        }


        return <>{imagenesfinal}</>
    }

    const onClickVerTodasOTAS = () => {
        let nuevoestado = !openallotas;

        setopenallotas(nuevoestado);
    }
      
  return (
    <>
          <OthelloNavbar type="transparent" />          
          <div className="wrapper">
              <OthelloHeader titulo="_channelmanager" subtitulo="_channelmanagerSubTitle" imagen={apps} conbotones={false} />
            {/*  <ProjectsSectionWeb />*/}
              {/*  <OthelloSolucionFeature7 titulo1="_appstitle1WEB" texto1="_appstitle1WEBtxt" titulo2="_appstitle2" texto2="_appstexto2" />*/}
              <div className="project-2 my-5">
                  <Container>
                      <Row>
                          <Col className="mx-auto text-center my-5" lg="12">
                              <h3 className="display-3">{t("_channelmanagertitle")}</h3>
                              <p className="lead">
                                  <Trans i18nKey="_channelmanagertexto" />
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </div>
              <div className="wrapper">                                  
                  <div className="project-2 mb-8">
                      <Container>
                          <Row>
                              <Col md="10" className=" mx-auto text-center my-0" lg="12">
                                  <Card className="card-plain">                                                                          
                                          {renderSlideOpcion(getDataChannels())}
                                          <br />                                                                                
                                  </Card>
                                  <Button color="danger" name="button" type="button" onClick={onClickVerTodasOTAS}>{!openallotas ? t("_verlistaintegraciones") : t("_ocultaslistaintegraciones")}</Button>
                          </Col>
                          </Row>
                          {openallotas ?
                              <Row>
                                  <Table6/>                                 
                              </Row> : ""}                                                                           
                      </Container>
                  </div>
              </div>
              <OthelloFooter />
          </div>        
    </>
  );
}

export default SolucionIntegracionesTerceros;

/*CodProduc	Descripcion
Marketing	Interfaz RMS ControlData
Marketing	Interfaz RMS Smart Revenue
Marketing	Interfaz RMS ReviewPro
Marketing	Interfaz RMS FidelTour
Marketing	Interfaz RMS TrustYou
Marketing	Interfaz RMS Customer Alliance
Marketing	Interfaz RMS BeOnPrice
Marketing	Interfaz RMS JourneyTok
Marketing	Interfaz RMS BedsRevenue
Marketing	Interfaz RMS HotelLink
Marketing	Interfaz RMS Lybra
Marketing	Interfaz RMS Jaippy
Marketing	Interfaz CRM QueryTech
Marketing	Interfaz CRM Connect PushTech

CashBox	Interfaz Caj�n CashGuard
CashBox	Interfaz Caj�n CashLogic
CashBox	Interfaz Caj�n CashKeeper

Channel	Interfaz Channel Parity Rate
Channel	Interfaz Channel SiteMinder
Channel	Interfaz Channel AvailPro
Channel	Interfaz Channel RateTiger
Channel	Interfaz Channel Synergy
Channel	Interfaz Channel YieldPlanet

Dom�tica	Interfaz Tarificador Char
Dom�tica	Interfaz Cerraduras Tesa
Dom�tica	Interfaz Cerraduras Inhova

Otros	Interfaz API RoomMatik
Otros	Interfaz App Rossa Connect
Otros	Interfaz PayTef
Otros	Interfaz Lector Gesvi
Otros	Interfaz Lector Delta

Contabilidad	SAGE
Contabilidad	Contaplus
Contabilidad	A3
Contabilidad	Altai
Contabilidad	Geyce
Contabilidad	Monitor
Contabilidad	Navision
Contabilidad	VisualConta
Contabilidad	Dimoni*/
