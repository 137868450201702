import React from "react";

// reactstrap components
import {
  Button,  
  NavLink,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useTranslation } from 'react-i18next';
import LogoMille from "../assets/img/brand/white.png";
import OthelloVersionWeb from "../componentsothello/OthelloVersionWeb.jsx";
import OthelloWhatsApp from "./OthelloWhatsApp";

// Core Components

function OthelloFooter() {

    const { t } = useTranslation();

  return (
    <>
      <footer className="footer footer-big bg-gradient-default">
        <Container>
          <div className="content">
            <Row className="mb-5">
              <div className="column mx-auto">
                <img
                  alt="..."
                  className="logo logo-sm"
                                  src={LogoMille}
                ></img>
              </div>
            </Row>
            <Row>
              <Col md="4" xs="6">
                <div className="column">
                  <h4 className="mb-4">{t("_about")}</h4>
                  <ul className="links-vertical">
                        <p>
                         {t("_aboutus")}
                        </p>
                    </ul>                  
                </div>
              </Col>
              <Col md="2" xs="6">
                <div className="column">
                                  <h4 className="mb-4">{t("_soluciones")}</h4>
                  <ul className="links-vertical">
                    <li>
                                          <a href="https://pms.othello.es" target="blank">
                        Pms Web
                      </a>
                    </li>
                                      <li>
                                          <a href="/soluciones/terminal-punto-de-venta">
                        Tpv
                      </a>
                    </li>
                    <li>
                                          <a href="/soluciones/back-office" > 
                                          Almacen
                      </a>
                    </li>
                    <li>
                                          <a href="/soluciones/aplicaciones-moviles" >
                        Apps Moviles
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
                <Col md="3" xs="6">
                    <div className="column">
                        <h4 className="mb-4">{t("_needhelp")}</h4>
                        <div className="social-feed">
                            <div className="feed-line">
                                          <i className="fas fa-phone"></i><p><a href="tel:+34915020400">Tel&eacute;fono: 91 502 04 00</a></p>
                            </div>
                            <div className="feed-line">
                                          <i class="fas fa-envelope"></i><p><a href='mailto:info@millenium-soft.es'>info@millenium-soft.es</a></p>
                            </div>
                            <div className="feed-line">
                                          <i class="fas fa-home"></i><p>Avd. Ciudad de Barcelona, 204 1&deg;A. 28007 - Madrid</p>
                            </div>                            
                        </div>
                    </div>
                </Col>
              <Col md="3">
                <div className="column">                                  
                                  <h4 className="mb-4">{t("Siguenos")}</h4>
                                  <ul className="social-buttons">
                                      <li>
                                          <Button
                                              className="btn-icon"
                                              color="twitter"
                                              type="button"
                                              href="https://twitter.com/MilleniumSoft"
                                              target='_blank'
                                          >
                                              <span className="btn-icon">
                                                  <i className="fab fa-twitter"></i>
                                              </span>
                                          </Button>
                                      </li>
                                      <li>
                                          <Button
                                              className="btn-icon"
                                              color="facebook"
                                              type="button"
                                              href="https://www.facebook.com/MilleniumSoft?fref=ts"
                                              target='_blank'
                                          >
                                              <span className="btn-icon">
                                                  <i className="fab fa-facebook"></i>
                                              </span>
                                          </Button>
                                      </li>
                                      <li>
                                          <Button className="btn-icon" color="youtube" type="button" href="https://www.youtube.com/user/othellomilleniumsoft" target='_blank'>
                                              <span className="btn-icon">
                                                  <i className="fab fa-youtube"></i>
                                              </span>                                              
                                          </Button>                                          
                                      </li>
                                      <li>
                                          <Button className="btn-icon" color="slack" type="button" href="http://elblogdeothello.blogspot.com/" target='_blank'>                                             
                                              <span className="btn-inner--icon">
                                                  <i class="fas fa-rss"></i>
                                              </span>
                                          </Button>
                                      </li>
                                  </ul>
                                  <ul className="social-buttons">
                                      <li>
                                          <Button color="danger" name="button" type="button" href="/soporte"><i class="fas fa-headset"></i> Soporte 24x7</Button>
                                      </li>
                                      <li>
                                          <OthelloWhatsApp soloicono={true} />
                                      </li>
                                  </ul>                                 
                                  <p style={{ marginTop: 5 }}><OthelloVersionWeb /></p>                                  
                </div>
              </Col>
                      </Row>                                                           
          </div>
          <hr className="bg-white opacity-3"></hr>
          <div className="column">
            <ul className="d-flex justify-content-center">             
              <li>
                    <NavLink href="/aviso-legal" >{t("_aviso")}</NavLink>
              </li>
              <li>
                <NavLink href="/privacidad" >{t("_politica")}</NavLink>
                </li>
                <li>
                    <NavLink href="/cookies">{t("_cookies")}</NavLink>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default OthelloFooter;
