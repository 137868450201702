import React from "react";
// react plugin used to create google maps
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import clientes from "../assets/img/trabajanosotros.jpg";
import DocumentMeta from 'react-document-meta';
import defaultImage from "../assets/img/image_placeholder.jpg";

import { useTranslation } from 'react-i18next';

import { useLocation } from "react-router-dom";

function Blog() {

    const { t } = useTranslation();
    const { search } = useLocation();

    React.useEffect(() => {
        document.body.classList.add("home-page");

        if (newsOpen == null) {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        }

        return function cleanup() {
            document.body.classList.remove("home-page");
        };
    });


    const [news, setNews] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [newsOpen, setNewsOpen] = React.useState([]);

    //Hook de efectos para la obtencion de las regiones que forman el menu
    React.useEffect(() =>
    {
        let url = search;
        let params = new URLSearchParams(url);

        let idnewsfinal = 0;

        if (params.has('news'))
        {
            let noticiaid = params.get('news');
            idnewsfinal = parseInt(noticiaid, 0);            
        }

        fetch('api/Data/GetNoticias')
            .then(response => response.json())
            .then(data =>
            {
                if (idnewsfinal !== 0)
                {
                    var resul = [];

                    //Si dentro de los datos recibidos esta el id pasado por parametro
                    if (data != null && data.length > 0 && data.some(x => x.id_noticia === idnewsfinal))
                    {
                        resul.push({ id_noticia: idnewsfinal });
                        setNewsOpen(resul);
                    }
                }

                setNews(data);
                setLoading(false)

            }).catch(error => {
                setNews([]);
                setLoading(false)
            });

    }, [loading]);

    const onClickVerMasNoticias = (noticia) =>
    {
        //Si esta abierto
        if (newsOpen != null && newsOpen.length > 0 && newsOpen.some(x => x.id_noticia == noticia.id_noticia))
        {
            var resul = [];

            newsOpen.forEach(x =>
            {
                if (x.id_noticia !== noticia.id_noticia)
                    resul.push(x);
            });

            setNewsOpen(resul);            
        }
        //Sino, es que debemos abrir
        else {
            newsOpen.push({ id_noticia: noticia.id_noticia });
            setNewsOpen([...newsOpen]);           
        }
    }

    const datanews = !loading && news != null && news.length > 0 ?
        news.map(x =>
        {
            let open = newsOpen != null && newsOpen.length > 0 && newsOpen.some(y => y.id_noticia == x.id_noticia);
            let imageshow = x.imageurlbig != null && x.imageurlbig != '' ? 'data:image/png; base64,' + x.imageurlbig : defaultImage;
            let link = x.link != null && x.link != '' ? x.link : '';

            return <Card className="card-blog card-plain blog-horizontal mb-5">
                <Row>
                    <Col lg="4">
                        <div className="card-image shadow" >
                            {link !== '' ? <a href={link} target='_blank' rel="noreferrer"><img alt="..." className="img rounded" src={imageshow} /></a> :
                                <img alt="..." className="img rounded" src={imageshow} />}
                        </div>
                    </Col>
                    <Col lg="8">
                        <CardBody>
                            <CardTitle tag="h3">                                
                                {<div dangerouslySetInnerHTML={{ __html: x.titulo }} />}
                            </CardTitle>
                            <div className="author">
                                <div className="meta">{x.fecha}</div>
                            </div>
                            <p className="card-description">
                                {<div dangerouslySetInnerHTML={{ __html: x.descripcioncorta }} />}
                                {open ? <><br />{<div dangerouslySetInnerHTML={{ __html: x.descripcion }} />}</> : ""}
                                {" "}
                                {x.descripcion != null && x.descripcion !== "" ?
                                    <a className="text-info" onClick={() => onClickVerMasNoticias(x)}>
                                        {open ? t("_leermenos") : t("_leermas")}
                                    </a> : ""}
                            </p>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        }) : "";


    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | Noticias',
        description: 'Mantente informado sobre nuestras novedades',
        canonical: 'https://www.millenium-soft.es/blog',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }


    return (
        <>
            <DocumentMeta {...meta} />
            <OthelloNavbar /*type="transparent" */ />
            <div className="wrapper">
                <OthelloHeader titulo="_blogmillenium" subtitulo="_blogheadertitle" imagen={clientes} conbotones={false} />
                <div className="project-2 my-5">
                    <Container>                        
                        <Row>
                            <Col className="mx-auto text-center my-5" lg="12">
                                {/*<h6 className="category text-muted">Our work</h6>*/}
                                <h3 className="display-3">{t("_blogtitle")}</h3>                                
                            </Col>
                        </Row>
                    </Container>
                </div>
                <section className="blogs-3">
                    <Container>
                        <Row>
                            <Col className="mx-auto" lg="10" md="8">  
                                {datanews}
                            </Col>
                        </Row>
                    </Container>
                </section>
                <OthelloFooter />
            </div>
        </>
    );
}

export default Blog;
