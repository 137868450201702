
import React from "react";

// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloHomeFeature2 from "../componentsothello/home/OthelloHomeFeature2.js";
import OthelloHomeFeature3 from "../componentsothello/home/OthelloHomeFeature3.js";
import OthelloHomeFeature4 from "../componentsothello/home/OthelloHomeFeature4.js";
import OthelloHomeBlogs2 from "../componentsothello/home/OthelloHomeBlogs2.js";

import CookieConsent from "react-cookie-consent";
import DocumentMeta from 'react-document-meta';

import { useTranslation } from 'react-i18next';

function Home() {

    const { t } = useTranslation();

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera. Software PMS',
        description: 'Programa de gestión hotelera, gestione los procesos de recepción, restauración, Eventos, Spa, gobernanta, reservas online y channel manager.',
        canonical: 'https://www.millenium-soft.es/',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

  return (
      <>
          <DocumentMeta {...meta} />
          <OthelloNavbar type="dark" />
          <div className="wrapper">
              <OthelloHeader conbotones={true} />              
              <OthelloHomeFeature2 />
              <OthelloHomeFeature3 />
              <OthelloHomeFeature4 />               
              {/*<Feature7 />*/}
              <OthelloHomeBlogs2/>
              {/*<Blogs1/>*/}
              {/*<Projects2/>*/}              
              <OthelloFooter/>
              <CookieConsent location="bottom" cookieName="myAwesomeCookieName3" expires={999} overlay style={{ background: "#2B373B" }}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }} buttonText="Aceptar">
                  {t("_consentimientocookies")}                  
              </CookieConsent>             
      </div>
    </>
  );
}      

export default Home;
