import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionAppsMovilesProjects2 from "../componentsothello/solucion/appsmoviles/OthelloSolucionAppsMovilesProjects2";
import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";

import image1 from "../assets/img/pages/photo-15.jpg";
import apps from "../assets/img/appsmoviles.jpg";
// reactstrap components
import {
    
} from "reactstrap";

function Solucionapps()
{  
    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

  return (
    <>
          <OthelloNavbar type="transparent" />
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_appsmovil" subtitulo="_appsheadertitle" imagen={apps} conbotones={false} />
              <OthelloSolucionAppsMovilesProjects2 />
              <OthelloSolucionFeature7 titulo1="_appstitle1" texto1="_appstexto1" titulo2="_appstitle2" texto2="_appstexto2" />
              <OthelloSolucionFeature4Left titulo="_appstitleinfo1" texto="_appstextoinfo1" imagen={image1} />           
              <OthelloFooter />
          </div>        
    </>
  );
}

export default Solucionapps;
