import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import trabajanosotros from "../assets/img/trabajanosotros.jpg";
import calidad from "../assets/img/calidad.jpg";

import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";

import { Trans, useTranslation } from 'react-i18next';

function TrabajaConNosotros() {

    const { t } = useTranslation();

    React.useEffect(() => {
        document.body.classList.add("home-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("home-page");
        };
    });

    return (
        <>
            <>
                <OthelloNavbar /*type="transparent" */ />
                <div className="wrapper">
                    <OthelloHeader titulo="_trabaja" subtitulo="_trabajaheadertitle" imagen={trabajanosotros} conbotones={false} />
                    <div className="project-2 my-5">
                        <Container>
                            <Row>
                                <Col className="mx-auto text-center my-5" lg="12">
                                    <h3 className="display-3">{t("_trabajatitle")}</h3>
                                    <p className="lead">
                                        <Trans i18nKey="_trabajatexto" components={<a />} />                                        
                                    </p>
                                    <br/>
                                    <Button color="danger" name="button" type="button" href="/contacto">Contacta con Nosotros</Button>                  
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <OthelloSolucionFeature7 titulo1="_trabajatitle1" texto1="_trabajatexto1" titulo2="_trabajatitle2" texto2="_trabajatexto2" />
                    <OthelloSolucionFeature4Left titulo="_trabajatitleinfo1" texto="_trabajatextoinfo1" imagen={calidad} />     <br /> <br />
                    <OthelloFooter />
                </div>
            </>
        </>
    );
}

export default TrabajaConNosotros;