import React from "react";

// reactstrap components
import {   
    Card,
    CardBody,    
    CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";

import { Trans, useTranslation } from 'react-i18next'; 
// Core Components

function ProjectsSection() {

    const { t } = useTranslation();

    return (
        <>
            <div className="project-2 my-5">
                <Container>
                    <Row>
                        <Col className="mx-auto text-center my-5" lg="12">
                            <h3 className="display-4">{t("_backofficetitle")}</h3>
                        </Col>
                    </Row>                                       
                    <Row>                       
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto">
                                        <i class="fas fa-truck"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4"> 
                                        {t("_backofficeopc4title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_backofficeopc4texto")}                                        
                  </p>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto">
                                        <i class="fas fa-wallet"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_backofficeopc1title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_backofficeopc1texto")}
                                    </p>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto">
                                        <i class="fas fa-cash-register"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_backofficeopc5title")}
                  </CardTitle>
                                    <p className="card-description">
                                        {t("_backofficeopc5texto")}                                        
                  </p>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto">
                                        <i class="fas fa-mobile-alt"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_backofficeopc6title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        <Trans i18nKey="_backofficeopc6info"
                                            components={<a />}>
                                        </Trans>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto">
                                        <i class="fas fa-file-invoice-dollar"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_backofficeopc3title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_backofficeopc3texto")}
                                    </p>
                                </CardBody>

                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto">
                                        <i class="fas fa-hotel"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_backofficeopc2title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_backofficeopc2texto")}
                                    </p>
                                </CardBody>
                            </Card>                            
                        </Col>                        
                    </Row>
                    <br></br>
                </Container>
            </div>
        </>
    );
}

export default ProjectsSection;