import React from "react";

// reactstrap components
import {
  Button, 
  Row,
  Col,
} from "reactstrap";


import { Trans, useTranslation } from 'react-i18next';
import ImageFondo from "../assets/img/sections/header-doc.jpeg"
import OthelloWhatsApp from "./OthelloWhatsApp";

// Core Components

function OthelloHeader({ ...props}) {

    //variables de las propiedas recibidas
    const { titulo, subtitulo,  imagen, conbotones } = props;
    const { t } = useTranslation();

    let titulofinal = titulo != null && titulo != "" ? titulo : "Millenium Soft";
    let subtitulofinal = subtitulo != null && subtitulo != "" ? subtitulo : "_titulo";
    let imagenfinal = imagen != null && imagen != "" ? imagen : ImageFondo;

  return (
      <>    
          <div className="page-header page-header-small header-filter">
              <div
                  className="page-header-image"
                  style={{
                      backgroundImage: "url(" + imagenfinal + ")",
                  }}
              ></div>
              <div className="content-center">
                  <Row>
                      <Col className="mx-auto text-center" md="6">
                          <h1 className="title text-white">
                              {t(titulofinal)}
                          </h1>
                          <h1 className="lead text-white">   
                              <Trans i18nKey={subtitulofinal} />                                      
                          </h1>
                          {conbotones ? <>
                              <Button color="info" name="button" type="button" href="/soluciones/kit-digital"><i class="fas fa-mobile"></i> Kit Digital</Button>
                              <Button color="danger" name="button" type="button" href="/Soluciones/Othello"><i class="fas fa-info-circle"></i> Mas Informacion</Button>
                              <Button color="danger" name="button" type="button" href="/soporte"><i class="fas fa-headset"></i> Soporte 24x7</Button>                            
                              <OthelloWhatsApp  soloicono={true} />
                          </> : ""}                          
                      </Col>
                  </Row>
              </div>
          </div>      
    </>
  );
}

export default OthelloHeader;
