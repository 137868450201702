import React from "react";

// reactstrap components
import { 
    Card,
    CardBody,    
    CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";

import { Trans, useTranslation } from 'react-i18next';

// Core Components

function ProjectsSection() {

    const { t } = useTranslation();

    return (
        <>
            <div className="project-2 my-5">
                <Container>
                    <Row>
                        <Col className="mx-auto text-center my-5" lg="12">
                            <h3 className="display-3">{t("_othelloservtitle")}</h3>                           
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto">
                                        <i class="fas fa-desktop"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_othelloservopc1title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        <Trans i18nKey="_othelloservopc1texto"
                                            components={<br />} />
                                    </p>                                   
                                </CardBody>
                                {/*  <CardFooter>
                                    <Button
                                        className="text-secondary"
                                        color="link"
                                        type="button"
                                    >
                                        <i className="ni ni-glasses-2"></i>
                      Check out now
                    </Button>
                                </CardFooter>*/}
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto">
                                        <i class="fas fa-user-friends"></i>                                        
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_othelloservopc2title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        <Trans i18nKey="_othelloservopc2texto"
                                            components={<br />} />
                                    </p>                                   
                                </CardBody>                               
                            </Card>
                        </Col>
                        <Col lg="4" md="6">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto">
                                        <i class="fas fa-chart-line"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_othelloservopc3title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_othelloservopc3texto")}                                        
                                    </p>                                    
                                </CardBody>                              
                            </Card>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col lg="4" md="4">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto">
                                        <i class="fas fa-book"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4"> 
                                        {t("_othelloservopc4title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        {t("_othelloservopc4texto")}                                        
                  </p>                                   
                                </CardBody>
                            </Card>
                        </Col>   
                        <Col lg="4" md="4">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mx-auto">
                                        <i class="fas fa-tablet-alt"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_othelloservopc5title")}
                  </CardTitle>
                                    <p className="card-description">
                                        <Trans i18nKey="_othelloservopc5texto"
                                            components={<br />} />
                  </p>                                   
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="4">
                            <Card className="card-project">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto">
                                        <i class="fas fa-tools"></i>
                                    </div>
                                </a>
                                <CardBody>
                                    <CardTitle className="mt-3" tag="h4">
                                        {t("_othelloservopc6title")}
                                    </CardTitle>
                                    <p className="card-description">
                                        <Trans i18nKey="_othelloservopc6texto"
                                            components={<br />} />
                                    </p>                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProjectsSection;