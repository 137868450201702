import React from "react";

// reactstrap components
import {
    Card,
    CardBody,    
    CardTitle,
    Container,
    CardText,
    CardImg,
    Row,
    Col,
} from "reactstrap";

import image1 from "../../../assets/img/tpvenmaquetado.png";
import managerenmaquetado from "../../../assets/img/managerenmaquetado.png";
import keepingenmaquetado from "../../../assets/img/keepingenmaquetado.png";
import billingenmaquetado from "../../../assets/img/billingenmaquetado.png";
import billing2enmaquetado from "../../../assets/img/billing2enmaquetado.png";
import technicalenmaquetado from "../../../assets/img/technicalenmaquetado.png";
import claridgeenmaquetado from "../../../assets/img/claridgeenmaquetado.png";
import spaenmaquetado from "../../../assets/img/spaenmaquetado.png";
import inventenmaquetado from "../../../assets/img/inventenmaquetado.png";

import { Trans, useTranslation } from 'react-i18next';
 
// Core Components

function ProjectsSection() {

    const { t } = useTranslation();

    return (
        <>
            <div className="project-2 my-5">
                <Container>
                    <Row>
                        <Col className="mx-auto text-center my-5" lg="12">
                            <h3 className="display-3">{t("_appstitle")}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.othello.tpvtablet"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={image1}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopc1title")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopc1texto"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.othello.manager"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={managerenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopc2title")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopc2texto"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.gobernantatablet"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={keepingenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopc3title")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopc3texto"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="justify-content-center">
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.visortablet"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={billing2enmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopc4title")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopc4texto"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.visortablet"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={billingenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopc5title")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopc5texto"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.othello.mantenimiento"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={technicalenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopc6title")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopc6texto"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg="4" md="6">                           
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={claridgeenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appswebPersoTitle")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appswebPerso"
                                                    components={<a />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>                            
                        </Col>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.othello.spawellness"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={spaenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopcSpaTitle")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopcSpa"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                        <Col lg="4" md="6">
                            <a
                                target={"_blank"}
                                rel="noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.othello.appstocktaking"
                            >
                                <Card>
                                    <CardImg
                                        alt="..."
                                        src={inventenmaquetado}
                                        top
                                    ></CardImg>
                                    <CardBody>
                                        <CardTitle className="h4 mb-0">{t("_appsopcIventarioTitle")}</CardTitle>
                                        <CardText className="mt-1">
                                            <p className="card-description" style={{ color: "#525f7f" }} >
                                                <Trans i18nKey="_appsopcInventario"
                                                    components={<br />} />
                                            </p>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProjectsSection;