import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
    Container,    
} from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import trabajanosotros from "../assets/img/trabajanosotros.jpg";

import { Trans } from 'react-i18next';

function Privacidad() {    

    React.useEffect(() => {
        document.body.classList.add("home-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("home-page");
        };
    });

    return (
        <>
            <>
                <OthelloNavbar /*type="transparent" */ />
                <div className="wrapper">
                    <OthelloHeader titulo="_politica" subtitulo="" imagen={trabajanosotros} conbotones={false} />
                    <div className="project-2 my-5">
                        <Container>
                    <p >
                        <Trans i18nKey="_privacidadtexto" />
                            </p>
                        </Container>
                    </div>
                    <OthelloFooter />
                </div>
            </>
        </>
    );
}

export default Privacidad;