import React from 'react';

import {    
    Input,    
    Row, Col,    
    Spinner
} from "reactstrap";


// nodejs library to set properties for components
import OthelloModalBase from "../../componentsothello/OthelloModalBase";

import { useTranslation } from 'react-i18next';

export default function DialogUpdatePassword({ ...props }) {

    const { t } = useTranslation();

    const { id, email, onCloseUpdatePassword } = props;

    const [errordata, setErrorData] = React.useState(0);
    const [errorliteral, setErrorLiteral] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
   

    const OnModalResul = (modo, result, valor) => {

        //Si pulso
        if (result)
            SaveCambioPass();
        //sino, es que se trata de un cancelar
        else if (onCloseUpdatePassword != null)
            onCloseUpdatePassword(result);
    }    

    const SaveCambioPass = () =>
    {
        let formulario = document.getElementById('formcambiopass');

        if (formulario != null)
        {
            setLoading(true);
            setErrorData("");
            setErrorLiteral("");

            let formData = new FormData(formulario);

            fetch('api/Data/CambioPassUser', {
                method: 'POST',
                body: formData,
            }).then(response => response.json()).then(res =>
            {
                //Si hubo error
                if (res == null || (res != null && !res.loginok))
                {
                    if (res != null) {
                       
                        setErrorData(res.exceptionmessage);
                        setErrorLiteral(res.exceptionmessagetraducible);
                    }

                    setLoading(false);
                }
                else
                {
                    if(id == -1)
                        window.location.assign('/micuenta')
                    else
                        onCloseUpdatePassword(true);
                }
            }).catch(error => {               
            });
        }
    };


    let errorinfo = ""

    if (errorliteral != null && errorliteral != "")
        errorinfo = <p className="lead text-red my-0"><b>{t(errorliteral)}</b></p>;
    else if (errordata != null && errordata != "")
        errorinfo = <p className="lead text-red my-0"><b>{errordata}</b></p>;   

    return (
        <OthelloModalBase open={true} titulo={t("_cambiocontrasena")} onCloseResulModal={OnModalResul} textobotonaceptar={t("_guardar")} >           
            <form id="formcambiopass">
                <input id="id" name="id" type="id" type="hidden"  value={id != null ? id : -1} />
                <input id="email" name="email" type="hidden" value={email} />
                <Row>
                    <Col md="4">
                        <div className="js-form-message mb-4">
                            <label className="form-label">{t("_oldpass")}</label>
                            <Input
                                id="oldpass"
                                name="oldpass"
                                aria-label=""
                                placeholder=""
                                required=""
                                type="password"
                            ></Input>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="js-form-message mb-4">
                            <label className="form-label">{t("_newpass")}</label>
                            <Input
                                id="newpass"
                                name="newpass"
                                aria-label=""
                                placeholder=""
                                required=""
                                type="password"
                            ></Input>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="js-form-message mb-4">
                            <label className="form-label">{t("_repeatpass")}</label>
                            <Input
                                id="repeatpass"
                                name="repeatpass"
                                aria-label=""
                                placeholder=""
                                required=""
                                type="password"
                            ></Input>
                        </div>
                    </Col>                   
                </Row>
                {loading ? <center>
                    <Spinner type="grow" className="text-primary">
                        <span className=" sr-only">Loading...</span>
                    </Spinner></center> : ""}
                {errorinfo}<br />
            </form>
            </OthelloModalBase>       
    );
}

DialogUpdatePassword.propTypes =
{
   
};