import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import Imagill from "../../assets/img/ill/p31.svg";

// Core Components

function OthelloHomeFeature3() {
    const { t } = useTranslation();
    return (
        <>
            <div
                className="section features-3 my-5"
                style={{
                    backgroundImage: "url(" + Imagill + ")",
                }}
            >
                <Container>
                    <Row className="text-center justify-content-center">
                        <Col lg="8">
                            <h3 className="display-3 text-white">{t("_HomeBackGroundTitle")}
                                <span className="text-success">{t("_HomeBackGroundSubTitle")}</span>
                            </h3>
                            <p className="lead text-white">{t("_HomeBackGroundResume")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="row-grid mt-5">
                        <Col lg="6">
                            <div className="info info-horizontal bg-white">
                                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                                    <i class="fas fa-desktop text-info"></i>
                                </div>
                                <div className="description pl-4">
                                    <h1 className="title text-info">Front Office</h1>
                                    <p>{t("_HomeShortDescrip")}
                                    </p>
                                    <a
                                        className="text-info"
                                        href="/soluciones/othello"                                        
                                    >
                                        {t("_Knowmore")}
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="info info-horizontal bg-white">
                                <div className="icon icon-shape icon-shape-warning rounded-circle text-white">
                                    <i class="fas fa-utensils text-warning"></i>
                                </div>
                                <div className="description pl-4">
                                    <h1 className="title text-warning">Tpv</h1>
                                    <p>{t("_HomeShortDescripTPV")}
                                    </p>
                                    <a
                                        className="text-warning"
                                        href="/soluciones/terminal-punto-de-venta"                                        
                                    >
                                        {t("_Knowmore")}
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="row-grid">
                        <Col lg="6">
                            <div className="info info-horizontal bg-white">
                                <div className="icon icon-shape icon-shape-danger rounded-circle text-white">
                                    <i class="fas fa-tablet-alt text-danger"></i>
                                </div>
                                <div className="description pl-4">
                                    <h1 className="title text-danger">{t("_appmoviles")}</h1>
                                    <p>{t("_HomeShortDescripApps")}
                                    </p>
                                    <a
                                        className="text-danger"
                                        href="/soluciones/aplicaciones-moviles"                                        
                                    >
                                        {t("_Knowmore")}
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="info info-horizontal bg-white">
                                <div className="icon icon-shape icon-shape-success rounded-circle text-white">
                                    <i class="fas fa-chair text-success"></i>
                                </div>
                                <div className="description pl-4">
                                    <h1 className="title text-success">{t("_HometitleEvent")}</h1>
                                    <p>{t("_HomeShortEvents")}
                                    </p>
                                    <a
                                        className="text-success"
                                        href="/soluciones/eventos-y-salones"                                        
                                    >
                                        {t("_Knowmore")}
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="row-grid">
                        <Col lg="6">
                            <div className="info info-horizontal bg-white">
                                <div className="icon icon-shape icon-shape-primary rounded-circle text-white">
                                    <i class="fas fa-truck text-primary"></i>
                                </div>
                                <div className="description pl-4">
                                    <h1 className="title text-primary">{t("_HomeAlmac")}</h1>
                                    <p>{t("_HomeAlmacDescrip")}
                                    </p>
                                    <a
                                        className="text-primary"
                                        href="/soluciones/back-office"                                         
                                    >
                                        {t("_Knowmore")}
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="info info-horizontal bg-white">
                                <div className="icon icon-shape icon-shape-default rounded-circle text-white">
                                    <i class="fas fa-book text-default"></i>
                                </div>
                                <div className="description pl-4">
                                    <h1 className="title text-default">{t("_HomeROL")}</h1>
                                    <p>{t("_HomeROLDescrip")}
                                    </p>
                                    <a
                                        className="text-default"
                                        href="/soluciones/motor-onetbooking"                                       
                                    >
                                        {t("_Knowmore")}
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OthelloHomeFeature3;