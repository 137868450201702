import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionOthelloProjects2 from "../componentsothello/solucion/othello/OthelloSolucionOthelloProjects2";
import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";
import DocumentMeta from 'react-document-meta';
import image1 from "../assets/img/theme/lucy.jpg";
import image2 from "../assets/img/sections/ashim.jpg";
import image3 from "../assets/img/sections/thomas.jpg";
import othello from "../assets/img/othello.jpg";

function SolucionOthello() {

    React.useEffect(() => {
        document.body.classList.add("home-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("home-page");
        };
    });

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | PMS Othello',
        description: 'Descubre el PMS Othello®, la herramienta que te permitirá gestionar de forma fácil y eficaz las tareas diarias de tu hotel.',
        canonical: 'https://www.millenium-soft.es/soluciones/othello',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

    return (
        <>
            <DocumentMeta {...meta} />
            <OthelloNavbar /*type="transparent" *//>
            <div className="wrapper">
                <OthelloHeader titulo="_othello2" subtitulo="_othelloservheadertitle" imagen={othello} conbotones={false} />             
                <OthelloSolucionOthelloProjects2 />
                <OthelloSolucionFeature7 titulo1="_othelloservtitle1" texto1="_othelloservtexto1" titulo2="_othelloservtitle2" texto2="_othelloservtexto2" />
                <OthelloSolucionFeature4Left titulo="_othelloservtitleinfo1" texto="_othelloservtextoinfo1" imagen={image1} />
                <OthelloSolucionFeature4Right titulo="_othelloservtitleinfo2" texto="_othelloservtextoinfo2" imagen={image2} />
                <OthelloSolucionFeature4Left titulo="_othelloservtitleinfo3" texto="_othelloservtextoinfo3" imagen={image3} />
                <OthelloFooter />
            </div>
        </>
    );
}

export default SolucionOthello;
