import React from "react";

// reactstrap components
import { Container, Row, Col, CardTitle } from "reactstrap";

import { Trans, useTranslation } from 'react-i18next';

function Feature4({ ...props }) {

    const { t } = useTranslation();

    //variables de las propiedas recibidas
    const { titulo, texto, imagen} = props;

    return (
        <>
            <div className="section features-6" >                
                <Container>
                    <Row className="align-items-center">
                        <Col className="p-sm-0" lg="7">
                            <CardTitle tag="h1"> {t(titulo)}</CardTitle>
                            <div className="lead">
                                <Trans i18nKey={texto}
                                    components={<a/>}>
                                </Trans> 
                                </div>
                        </Col>
                        <Col className="mr-auto text-left mt-4" lg="4">
                            <img
                                alt="..."
                                className="ml-lg-5"
                                src={imagen}
                                width="100%"
                            ></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Feature4;