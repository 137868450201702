import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

import ImageA from "../../assets/img/sections/gandiapalace.png"
import ImageB from "../../assets/img/sections/hotelopera.png"
import ImageC from "../../assets/img/sections/casasjuderia.png"
import ImageD from "../../assets/img/sections/palaciovelada.png"

function OthelloHomeBlogs2() {
    const { t } = useTranslation();
    return (
        <>
            <section className="blogs-2">
                <Container fluid>
                    <Row className="mb-md-5">
                        <Col className="mx-auto" md="8">
                            <h3 className="display-3 text-center">{t("_HomeSucessCases")}</h3>
                            <p className="lead text-center">{t("_HomeSucessText")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <Card
                                className="card-blog card-background"
                                data-animation="zooming"
                            >
                                <div
                                    className="full-background"
                                    style={{
                                        backgroundImage:
                                            "url(" + ImageA + ")",
                                    }}
                                ></div>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardBody>
                                        <div className="content-bottom">
                                            <h6 className="card-category text-white opacity-8">
                                                Hotel Gandia Palace
                      </h6>
                                            <CardTitle tag="h5">PMS, Channel Manager, web corporativa, digital signature...</CardTitle>
                                        </div>
                                    </CardBody>
                                </a>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Card
                                className="card-blog card-background"
                                data-animation="zooming"
                            >
                                <div
                                    className="full-background"
                                    style={{
                                        backgroundImage:
                                            "url(" + ImageB + ")",
                                    }}
                                ></div>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardBody>
                                        <div className="content-bottom">
                                            <h6 className="card-category text-white opacity-8">
                                                Hotel Opera
                      </h6>
                                            <CardTitle tag="h5">PMS, Channel Manager, almac&eacute;n, web corporativa, digital signature...</CardTitle>
                                        </div>
                                    </CardBody>
                                </a>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Card
                                className="card-blog card-background"
                                data-animation="zooming"
                            >
                                <div
                                    className="full-background"
                                    style={{
                                        backgroundImage:
                                            "url(" + ImageC + ")",
                                    }}
                                ></div>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardBody>
                                        <div className="content-bottom">
                                            <h6 className="card-category text-white opacity-8">
                                                Las casas de la Juderia de Sevilla
                      </h6>
                                            <CardTitle tag="h5">PMS, Channel Manager, almac&eacute;n, FastCheckIn,OthelloPay...</CardTitle>
                                        </div>
                                    </CardBody>
                                </a>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Card
                                className="card-blog card-background"
                                data-animation="zooming"
                            >
                                <div
                                    className="full-background"
                                    style={{
                                        backgroundImage:
                                            "url(" + ImageD + ")",
                                    }}
                                ></div>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <CardBody>
                                        <div className="content-bottom">
                                            <h6 className="card-category text-white opacity-8">
                                                Hotel Palacio de los Velada
                      </h6>
                                            <CardTitle tag="h5">PMS, Channel Manager, almac&eacute;n, Eventos,Event viewer</CardTitle>
                                        </div>
                                    </CardBody>
                                </a>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default OthelloHomeBlogs2;