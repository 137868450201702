import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionBackOfficeProjects2 from "../componentsothello/solucion/backoffice/OthelloSolucionBackOfficeProjects2";
import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";

import backoffice from "../assets/img/backoffice.jpg";
import image2 from "../assets/img/sections/dane.jpg";
import image3 from "../assets/img/sections/broofoac.jpg";
import image4 from "../assets/img/faces/alejandro-escamilla.jpg";

function Solucionbackoffice() {
   
    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

  return (
      <>         
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_backoffice" subtitulo="_backofficeheadertitle" imagen={backoffice} conbotones={false} />
              <OthelloSolucionBackOfficeProjects2 />
              <OthelloSolucionFeature7 titulo1="_backofficetitle1" texto1="_backofficetexto1" titulo2="_backofficetitle2" texto2="_backofficetexto2" />
              <OthelloSolucionFeature4Left titulo="_backofficetitleinfo1" texto="_backofficetextoinfo1" imagen={image4} />
              <OthelloSolucionFeature4Right titulo="_backofficetitleinfo2" texto="_backofficetextoinfo2" imagen={image3} />
              <OthelloSolucionFeature4Left titulo="_backofficetitleinfo3" texto="_backofficetextoinfo3" imagen={image2} />
              <OthelloFooter />
          </div>      
    </>
  );
}

export default Solucionbackoffice;
