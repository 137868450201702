import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionOnetbookingProjects2 from "../componentsothello/solucion/onetbooking/OthelloSolucionOnetbookingProjects2";
import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";

import onetbooking from "../assets/img/onetbooking.jpg";
import extras from "../assets/img/Extras.jpg";
import image2 from "../assets/img/reservaweb.png";
import image3 from "../assets/img/sections/broofoac.jpg";

function SolucionOnetbooking() {

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

  return (
    <>
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_onetbooking" subtitulo="_onetbookingheadertitle" imagen={onetbooking } conbotones={false}/>             
              <OthelloSolucionOnetbookingProjects2 />  
              <OthelloSolucionFeature7 titulo1="_onetbookingtitle1" texto1="_onetbookingtexto1" titulo2="_onetbookingtitle2" texto2="_onetbookingtexto2" />
              <OthelloSolucionFeature4Left titulo="_onetbookingtitleinfo1" texto="_onetbookingtextoinfo1" imagen={extras}/>    
              <OthelloSolucionFeature4Right titulo="_onetbookingtitleinfo2" texto="_onetbookingtextoinfo2" imagen={image2} />    
              <OthelloSolucionFeature4Left titulo="_onetbookingtitleinfo3" texto="_onetbookingtextoinfo3" imagen={image3} />    
              <OthelloFooter />
      </div>
    </>
  );
}

export default SolucionOnetbooking;
