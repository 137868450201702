import React from "react";

// reactstrap components
import {
    Button,
    FormGroup, 
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Card,    
    CardBody,
    Spinner
} from "reactstrap";

import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloFooter from "../componentsothello/OthelloFooter";
import DialogUpdatePassword from "./Dialog/DialogUpdatePassword";

import ImagePrinc from "../assets/img/ill/register_bg.png";

import { useTranslation } from 'react-i18next';


function LoginUserPage() {

    const { t } = useTranslation();

    const [submitted, setSubmitted] = React.useState(false);
    const [error, setError] = React.useState(0);
    const [errordata, setErrorData] = React.useState(0);
    const [errorliteral, setErrorLiteral] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
    
    const [emailFocus, setEmailFocus] = React.useState("");
    const [passwordFocus, setPasswordFocus] = React.useState("");

    const [dialogopenupdatepass, setDialogOpenUpdatePass] = React.useState(null);

    React.useEffect(() => {
        document.body.classList.add("loginuser-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("loginuser-page");
        };
    }, []);


    React.useEffect(() =>
    {
        getHaySessionUsuario();
    });

    const getHaySessionUsuario = () => {

        fetch('api/Data/HaySessionUsuario')
            .then(response => response.json())
            .then(data => {
                if (data != null && data)
                    window.location.assign('/micuenta');
            }).catch(error => {
            });
    }

    const LoginUsuario = (event) =>
    {
        event.preventDefault();

        setLoading(true);
        setErrorData("");
        setErrorLiteral("");

        event.preventDefault();
        const data = new FormData(event.target);

        fetch('api/Data/LoginUsuario', {
            method: 'POST',
            body: data,
        }).then(response => response.json()).then(res =>
        {
            //Si hubo error
            if (res == null || (res != null && !res.loginok))
            {
                setError(1);

                if (res != null) {

                    if (res.regenerarpass)
                        setDialogOpenUpdatePass(res.email)                        

                    setErrorData(res.exceptionmessage);
                    setErrorLiteral(res.exceptionmessagetraducible);
                }

                setLoading(false);
            }
            else {

                window.location.assign('/micuenta')
            }
        }).catch(error => {
            setSubmitted(true);
            setError(2);
        });
    }

    const OnCloseUpdatePassword = (result) => {

        setDialogOpenUpdatePass(null)
    }

    function EsperandoResultados() {

        return <><center>
            <Spinner type="grow" className="text-primary">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-success">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-danger">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-warning">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-info">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-light">
                <span className=" sr-only">Loading...</span>
            </Spinner>
            <Spinner type="grow" className="text-default">
                <span className=" sr-only">Loading...</span>
            </Spinner>
        </center>
        </>

    }

    let errorinfo = ""

    if (errorliteral != null && errorliteral != "")
        errorinfo = <p className="lead text-red my-0"><b>{t(errorliteral)}</b></p>;
    else if (errordata != null && errordata != "")
        errorinfo = <p className="lead text-red my-0"><b>{errordata}</b></p>;


    let modalCambioPassword = "";
    if (dialogopenupdatepass != null && dialogopenupdatepass != "")
        modalCambioPassword = <DialogUpdatePassword id={-1} email={dialogopenupdatepass} onCloseUpdatePassword={OnCloseUpdatePassword}/>

    return (
        <>
            <OthelloNavbar type="transparent" />            
            <div className="wrapper">
            <div className="page-header">
                <div
                    className="page-header-image bg-default"
                    style={{
                        backgroundImage:
                            "url(" + ImagePrinc + ")",
                    }}                        
                    ></div>                    
                    <Container >
                        <>
                            <Card className="bg-secondary shadow border-0">                               
                                <CardBody className="px-lg-5 py-lg-5">                                    
                                    <div className="text-center text-muted mb-4">
                                        <h2>{t("_loginuser")}</h2>
                                        {t("_introducecredenciales")}
                                    </div>
                                    <form onSubmit={LoginUsuario}>
                                        <FormGroup className={"mb-3 " + emailFocus}>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    type="email"
                                                    onFocus={() => setEmailFocus("focused")}
                                                    onBlur={() => setEmailFocus("")}
                                                ></Input>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup className={passwordFocus}>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    id="pass"
                                                    name="pass"
                                                    placeholder="Password"
                                                    type="password"
                                                    onFocus={() => setPasswordFocus("focused")}
                                                    onBlur={() => setPasswordFocus("")}
                                                ></Input>
                                            </InputGroup>
                                        </FormGroup>
                                        {loading ? EsperandoResultados() : ""}
                                        {errorinfo}
                                        <div className="text-center">
                                            <Button className="my-4" color="danger" type="button" size='lg' type="submit">
                                                {t("_entrar")}
                                            </Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </>
                    </Container>
                    {modalCambioPassword}
                </div>
                <OthelloFooter />
            </div>
        </>
    );
}

export default LoginUserPage;  