import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import clientes from "../assets/img/trabajanosotros.jpg";

import OthelloNuestrosClientesProjects3 from "../componentsothello/nuestroclientes/OthelloNuestrosClientesProjects3";

import { Trans, useTranslation } from 'react-i18next';

function NuestrosClientes() {

    const { t } = useTranslation();

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

  return (
      <>
          <OthelloNavbar /*type="transparent" */ />
          <div className="wrapper">
              <OthelloHeader titulo="_cliensatis" subtitulo="_cliensatisheadertitle" imagen={clientes} conbotones={false} />
              <div className="project-2 my-5">
                  <Container>
                      <Row>
                          <Col className="mx-auto text-center my-5" lg="12">
                              <h3 className="display-3">{t("_clientessatistitle")}</h3>
                              <p className="lead">
                                  <Trans i18nKey="_clientessatistexto" />
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </div>              
              <OthelloNuestrosClientesProjects3/>                          
              <OthelloFooter />
          </div>
      </>
  );
}

export default NuestrosClientes;
