import React from "react";
// react plugin used to create google maps
// reactstrap components

// Core Components
import OthelloNavbar from "../componentsothello/OthelloNavbar";
import OthelloHeader from "../componentsothello/OthelloHeader";
import OthelloFooter from "../componentsothello/OthelloFooter";

import OthelloSolucionTpvProjects2 from "../componentsothello/solucion/tpv/OthelloSolucionTpvProjects2";
import OthelloSolucionFeature7 from "../componentsothello/solucion/OthelloSolucionFeature7";
import OthelloSolucionFeature4Left from "../componentsothello/solucion/OthelloSolucionFeature4Left";
import OthelloSolucionFeature4Right from "../componentsothello/solucion/OthelloSolucionFeature4Right";
import DocumentMeta from 'react-document-meta';

import tpv from "../assets/img/tpv.jpg";
import tpv2 from "../assets/img/tpv2.jpg";
import tpv3 from "../assets/img/appsmoviles.jpg";

function SolucionTpv() {

    React.useEffect(() => {
    document.body.classList.add("home-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
        document.body.classList.remove("home-page");
    };
    });

    const meta = {
        title: 'Millenium Soft , Programa de gestión hotelera | TPV',
        description: 'La solución para tu restaurante, cafetería, buffet o tienda. Othello® te ofrece el TPV con App móvil para la gestión ágil y eficaz, dentro del PMS del hotel.',
        canonical: 'https://www.millenium-soft.es/soluciones/terminal-punto-de-venta',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

  return (
      <>
          <DocumentMeta {...meta} />
          <OthelloNavbar type="transparent" />
          <OthelloNavbar /*type="transparent"*/ />
          <div className="wrapper">
              <OthelloHeader titulo="_tpv" subtitulo="_tpvheadertitle" imagen={tpv} conbotones={false} />
              <OthelloSolucionTpvProjects2 />
              <OthelloSolucionFeature7 titulo1="_tpvtitle1" texto1="_tpvtexto1" titulo2="_tpvtitle2" texto2="_tpvtexto2" />
              <OthelloSolucionFeature4Left titulo="_tpvtitleinfo1" texto="_tpvtextoinfo1" imagen={tpv2} />
              <OthelloSolucionFeature4Right titulo="_tpvtitleinfo2" texto="_tpvtextoinfo2" imagen={tpv} />              
              <OthelloSolucionFeature4Left titulo="_tpvtitleinfo3" texto="_tpvtextoinfo3" imagen={tpv3} />
              <OthelloFooter />
          </div>               
    </>
  );
}

export default SolucionTpv;
